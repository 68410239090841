import { Suspense, type PropsWithChildren } from "react";
import PageContext from "./../PageContext";
import Toaster from "./../../src/components/Toaster";
import Auth from "./../Auth";
import UserProfile from "./UserProfile";
import css from './PageShell.module.scss';
import WrapPromise from "./WrapPromise";
import logo from './logo.svg';
import Spinner from "../../src/components/Spinner";

const awaitingAuth = WrapPromise(Auth);

export default function PageShell({ children }: PropsWithChildren) {
  return <>
    <Suspense fallback={<Spinner />}>
      <PageContext.Provider value={awaitingAuth.read()} >
        <div className={css['nav-bar']} >
          <a href='/' className={css['logo-container']}>
            <img src={logo} />
          </a>
          <div className={css['menu']}>
            <PageContext.Consumer>
              {
                value =>
                  value &&
                  (value.roles?.includes('vrdb-upload')
                    ? <a className={css['navLink']} href='/import'>Import an asset</a>
                    : value.roles?.includes('project-author') &&
                    <a className={css['navLink']} href='/projects'>Create a project</a>)
              }
            </PageContext.Consumer>

            {
              location.pathname !== '/legacy' && (<a className={css['navLink']} href='/sessions'>Live sessions</a>)
            }
            <UserProfile />
          </div>
        </div>
        <Toaster>
          {children}
        </Toaster>
      </PageContext.Provider>
    </Suspense>
  </>;
}
