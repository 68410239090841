import { useCallback, useContext, useState, type ChangeEventHandler, useEffect } from 'react';
import SceneSelector from '../components/SceneSelector.tsx';
import Translate from './../components/Translate.tsx';
import PageContext from './../PageContext.ts';
import type ArtifactItem from '../types/ArtifactItem.ts';
import PageHeader from '../../src/components/PageHeader.tsx';
import Input from '../../src/components/Input.tsx';
import Pagination from '../../src/components/Pagination.tsx';
import PaginationWrapper from '../../src/components/PaginationWrapper.tsx';
import PageContent from '../../src/components/PageContent.tsx';
import Spinner from '../../src/components/Spinner.tsx';

const artifactAccessPath = import.meta.env.VIRTALIS_ARTIFACT_ACCESS_PATH;

export default function ArtifactPage({ legacyMode }: { legacyMode?: boolean; }) {
  const [nameFilter, setNameFilter] = useState('');
  const pageSize = 100;
  const [pageNo, setPageNo] = useState(1);
  const user = useContext(PageContext);
  const [errorMsg, setError] = useState('');
  const [artifacts, setArtifacts] = useState<ArtifactItem[]>([]);
  const [pendingRequest, setPendingRequest] = useState(false);
  const [artifactCount, setArtifactCount] = useState(0);

  const onSearchChange = useCallback<ChangeEventHandler<HTMLInputElement>>((v) => {
    const searchBox = v.target;

    setNameFilter(searchBox.value);
    setPageNo(1);
  }, [setPageNo, setNameFilter]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setPendingRequest(v => signal.aborted ? v : true);

    fetch(`${artifactAccessPath}?PageNumber=${pageNo}&PageSize=${pageSize}&ArtifactNameFilter=${nameFilter}`, {
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${user?.access_token}`
      },
      signal,
    }).then((response) => {
      if (!response.ok)
        setError(err => signal.aborted ? err : `Error ${response.status}: ${response.statusText}!`);
      return response.json();
    }).then(data => {
      const { artifacts, totalResults } = data as {
        artifacts: ArtifactItem[];
        totalResults: number;
      };

      setError('');
      setPendingRequest(v => signal.aborted ? v : false);
      setArtifactCount(v => signal.aborted ? v : totalResults);
      setArtifacts(v => signal.aborted ? v : artifacts);
    }).catch((err) => {
      const errorMsg = err instanceof Error ? `Error: ${err.message}` : `Error: ${err}`;
      setError(signal.aborted ? '' : errorMsg);
      setPendingRequest(v => signal.aborted ? v : false);
      setArtifactCount(v => signal.aborted ? v : 0);
      setArtifacts(v => signal.aborted ? v : []);
    });

    return () => {
      controller.abort();
    };
  }, [pageNo, nameFilter, user]);

  return (
    <PageContent>
      <PageHeader
        title={Translate("Scenes").concat(legacyMode ? ' (Legacy Mode)' : '')}
        subTitle={Translate("An overview of all available scenes")}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            placeholder={Translate('Filter by name')}
            onChange={onSearchChange}
          />
        </div>
      </PageHeader>

      <div style={{ margin: '12px', display: 'grid', alignItems:'start' }} >
        {pendingRequest && <Spinner style={{ placeSelf: 'center' }}>Loading scene list</Spinner>}
        <SceneSelector
          error={errorMsg}
          fetching={pendingRequest}
          visualisations={artifacts}
          mode={legacyMode && 'legacy'}
        />
      </div>

      <footer>
        <PaginationWrapper >
          <Pagination
            currentPage={pageNo}
            onPageRequest={setPageNo}
            totalPageCount={Math.ceil(artifactCount / pageSize)}
          />
        </PaginationWrapper>
      </footer>
    </PageContent>
  );
}
