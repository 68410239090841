import { useContext, useEffect, useState } from "react";
import Input from "../../src/components/Input";
import css from './General.module.scss';
import ProjectContext, { generateActionsWithUpdatedPublishTitle } from "./ProjectContext";
import GroupSelector from "../components/GroupSelector";
import FieldSet from "../../src/components/FieldSet";

export default function General() {
  const project = useContext(ProjectContext);
  const [scriptsEnabled, setScripting] = useState<boolean>();
  const [editGroups, setEditGroups] = useState<ReadonlySet<string>>();
  const [projectName, setProjectName] = useState<string>();

  const editGroupsWithFallback = editGroups ?? new Set(project.groups);
  const scriptsEnabledWithFallback = scriptsEnabled ?? project.allowScripts;

  useEffect(() => {
    if (projectName === undefined)
      return;

    const updatedActions = generateActionsWithUpdatedPublishTitle(project, projectName);
    project.setProjectState({ ...project, name: projectName, actions: updatedActions });
  }, [project, projectName]);


  useEffect(() => {
    if (scriptsEnabled === undefined)
      return;
    project.setProjectState({ ...project, allowScripts: scriptsEnabled });
  }, [project, scriptsEnabled]);

  useEffect(() => {
    if (editGroups === undefined)
      return;

    project.setProjectState({
      ...project,
      groups: [...editGroups]
    });
  }, [editGroups, project]);

  return <div className={css['controls']}>
    <label style={{ display: 'grid' }}>
      <strong>Project name</strong>
      <Input type="text" required spellCheck={false} defaultValue={project.name} onChange={(e) => { setProjectName(e.target.value); }} />
    </label>
    <hr />
    <GroupSelector selectedGroupState={[editGroupsWithFallback, setEditGroups]} >
      Who can edit this project
    </GroupSelector>
    <hr />
    <FieldSet onChange={(e) => {
      if (e.target instanceof HTMLInputElement)
        setScripting(!!parseInt(e.target.value));
    }} >
      <legend>Allow scripts</legend>
      <label><Input type="radio" value={1} name="scripting" defaultChecked={scriptsEnabledWithFallback} />Yes</label>
      <label><Input type="radio" value={0} name="scripting" defaultChecked={!scriptsEnabledWithFallback} />No</label>
    </FieldSet>
  </div>;
}
