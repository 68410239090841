import artifactThumbnail from '/assets/icon-artifact-loader.png';
import { DateTime } from 'luxon';
import CopyLink from './../../src/components/CopyLink';
import type ArtifactItem from '../types/ArtifactItem';
import Card from '../../src/components/Card';
import css from './SceneSelector.module.scss';
import CardList from '../../src/components/CardList';
import Alert from '../../src/components/Alert';

function toDisplayDate(isoDate = ''): string {
  return DateTime.fromISO(isoDate).toLocaleString(DateTime.DATETIME_MED);
}

const legacyUri = import.meta.env.VIRTALIS_SESSION_SERVER_LEGACY_URI as string;

export default function SceneSelector(props: {
  fetching: boolean,
  error: string,
  visualisations: ArtifactItem[],
  mode?: 'sessions' | 'legacy' | false;
}) {
  const { fetching, error, visualisations, mode } = props;

  // previously we filtered to only show latest revisions,
  // this is no longer required as our endpoint will now filter
  // them for us
  const artifactsToShow = visualisations;

  return (
    <>
      {error && <Alert severity="error">{`${error}. Please refresh the page to try again.`}</Alert>}
      {visualisations && Object.keys(visualisations).length > 0 && (
        <CardList layout='standard'>
          {artifactsToShow.map((a) => {
            const uri = mode === 'legacy' ? `${legacyUri}/${a.id}` : `${location.origin}/loading?${new URLSearchParams(
              mode === 'sessions' ? {
                sessionId: a.id
              } : {
                artifactId: a.id
              }).toString()}`;

            return <Card
              href={uri}
              key={a.id}
              image={
                mode !== 'sessions' &&
                <img
                  title={a.name}
                  src={artifactThumbnail}
                  style={{
                    margin: 'auto',
                    padding: 24,
                    aspectRatio: 1,
                    maxWidth: 'unset'
                  }}
                />}
              header={a.name}
              status={mode !== 'sessions' &&
                <CopyLink
                  icon={true}
                  ariaLabel="Copy link"
                  uri={uri}
                  linkCopiedMessage="Link to Artifact has been saved to your clipboard"
                  linkCopyFailMessage='Unable to copy link. Please try right clicking on the Artifact and selecting "Copy link address" instead.'
                  className={css.copyLink}
                />}>
              <small>
                {`${mode === 'sessions' ? 'Session started' : 'Latest version'}: ${toDisplayDate(a.createdDate)}`}
              </small>
              <span>
                {a.labels.map((l) => <div key={a.id + l}>{l}</div>)}
              </span>
            </Card>;
          })}
        </CardList>
      )}
      {(!visualisations || !Object.keys(visualisations).length) && !fetching && !error && (
        <Alert severity="info">
          There are no {mode !== 'sessions' ? "scenes" : "sessions"} to display
        </Alert>
      )}
    </>
  );
}
