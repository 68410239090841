import { createContext } from "react";

export type RemoveChild = (id: number) => void;
export type SubscribeFunc = ((
  id: number,
) => VoidFunction);
export type EnabledFunc = ((
  id: number,
  state: boolean,
) => void);
export type BlinkToFunc = ((
  id: number
) => void);

export type TreeSubscription = null | {
  subscribe: SubscribeFunc;
  updateEnabled: EnabledFunc;
  blinkTo: BlinkToFunc;
};

export type TreeFunction = null | {
  subscribe: (id: number) => void;
  unsubscribe: (id: number) => void;
  updateEnabled: (id: number, enabled: boolean) => void;
  blinkTo: (id: number) => void;
};

export default createContext<TreeFunction>(null);