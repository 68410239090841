import { useState, useCallback, type PropsWithChildren } from 'react'
import css from './Toaster.module.scss';
import ToastContext, { type ToasterContext } from './ToastContext';
import Toast from './Toast';
import type Alert from './Alert';

export default function Toaster(props: PropsWithChildren<{
  bottomAligned?: boolean;
  align?: 'left' | 'center' | 'right';
  reverseOrder?: boolean;
}>) {
  const {
    bottomAligned,
    align,
    reverseOrder,
    children
  } = props;

  const [toasts, setToasts] = useState<(Parameters<typeof Alert>[0] & { id: string, open: boolean })[]>([]);

  const showToast = useCallback<Required<ToasterContext>['showToast']>((message, opts) => {
    const toast: typeof toasts[0] = {
      id: (Math.random() * 234656234).toString(),
      severity: opts.type ?? 'info',
      children: message,
      open: true
    }

    setToasts(c => {
      return [...c, toast];
    });

    setTimeout(() => {
      setToasts(c => {
        toast.open = false;
        return [...c];
      })
    }, 4000);
  }, []);

  return <ToastContext.Provider value={{ showToast }}>
    <section className={css['toaster']} style={{
      flexDirection: reverseOrder ? 'column' : 'column-reverse',
      marginLeft: align === 'left' ? '1rem' : 'auto',
      marginRight: align === 'right' ? '1rem' : 'auto',
      marginTop: '1rem',
      marginBottom: '1rem',
      ...bottomAligned ? { bottom: 0 } : { top: 0 }
    }} >
      {
        toasts && toasts.map(({ id, open, ...props }) => {
          return <Toast
            onTransitionEnd={!open ? () => {
              setToasts(c => {
                return c.filter(v => v.id !== id);
              });
            } : undefined}
            key={id}
            open={open}
            {...props}
          />
        })
      }
    </section>
    {children}
  </ToastContext.Provider>
}
