import { useRef, type CSSProperties } from 'react';
import AnimationControls from './AnimationControls';
import Settings from './Settings';
import css from './ClientControls.module.scss';
import Dialog, { type DialogHandle } from '../../src/components/Dialog';

function Controller(props: { left: true; } | { right: true; }) {
  const mapping = 'left' in props ? 'left' : 'right' in props ? 'right' : undefined;

  const thumbGridArea: CSSProperties['gridArea'] = mapping === 'left' ? 'span 2 / span 2' : '1 / 3 /span 2 / span 2';
  const button0GridArea: CSSProperties['gridArea'] = mapping === 'left' ? '3 / 2 / 3 / span 2' : '3 / 2 / 3 / span 2';
  const button1GridArea: CSSProperties['gridArea'] = mapping === 'left' ? '2 / 3 / 2 / span 2' : '2 / 1 / 2 / span 2';

  return <div
    className={css['webxr']}
    style={{
      display: 'grid',
      gridTemplateColumns: 'auto auto auto',
      gridTemplateRows: 'auto auto',
      borderWidth: 2,
      width: 'max-content',
      textAlign: 'center',
      justifyItems: 'center',
      alignItems: 'center',
    }}
  >
    <span />
    <input
      id={`${mapping}-trigger`}
      readOnly
      title='Unused'
      style={{ height: 14, width: 30, borderRadius: '50% 50% 0 0', marginBottom: -10 }}
    />
    <span />
    {mapping === 'right' ? <input
      id={`${mapping}-grip`}
      readOnly
      style={{ marginRight: -2, height: 40, marginTop: -28, width: 8, borderRadius: '50% 0 0 50%', borderWidth: '2px 0 2px 2px' }}
    /> : <div style={{ width: 8 }} />}
    {mapping === 'left' ? <input
      id={`${mapping}-grip`}
      readOnly
      style={{ marginLeft: -2, height: 40, marginTop: -28, width: 8, borderRadius: '0 50% 50% 0', borderWidth: '2px 2px 2px 0', gridArea: '2 / 3' }}
    /> : <div style={{ width: 8 }} />}

    <div
      style={{
        gridArea: '2 / 2',
        background: '#444',
        display: 'grid',
        justifyItems: 'center',
        paddingBottom: 25,
        gap: 4,
        border: 'solid 2px currentColor',
        borderTopLeftRadius: '70px',
        borderTopRightRadius: '70px',
        borderBottomRightRadius: '60% 80%',
        borderBottomLeftRadius: '60% 80%',
      }}
    >
      <div style={{
        background: 'gray',
        borderRadius: '50%',
        border: 'solid 2px currentColor',
        display: 'grid',
        padding: 6,
        margin: 4,
        justifyItems: mapping === 'left' ? 'end' : undefined,
      }} >
        <input
          id={mapping + '-thumb'}
          readOnly
          style={{ gridArea: thumbGridArea, margin: 3, width: 18, aspectRatio: 1, placeContent: 'center', borderRadius: '50%' }}
        />
        <input
          id={mapping + '-button-0'}
          readOnly
          title='Unused'
          style={{ gridArea: button0GridArea, fontSize: '0.6rem', width: 10, aspectRatio: 1, placeContent: 'center', borderRadius: '50%' }}
          value={mapping === 'right' ? 'A' : 'X'}
        />
        <input
          id={mapping + '-button-1'}
          readOnly
          title='Unused'
          style={{ gridArea: button1GridArea, fontSize: '0.6rem', width: 10, aspectRatio: 1, placeContent: 'center', borderRadius: '50%' }}
          value={mapping === 'right' ? 'B' : 'Y'}
        />
      </div>
      <span />
      {mapping === 'right' && <input
        id='home'
        readOnly
        title='Device controlled menu'
        disabled
        style={{ aspectRatio: 1, placeContent: 'center', borderRadius: '50%', width: 12, fontSize: '0.6rem' }}
        value={'\ue88a'}
      />}
      {mapping === 'left' && <input
        id='menu'
        readOnly
        title='Browser controlled menu'
        disabled
        style={{ aspectRatio: 1, placeContent: 'center', borderRadius: '50%', width: 12, fontSize: '0.6rem' }}
        value={'\ue5d2'}
      />}
    </div>
  </div>;
}

export default function ClientControls() {
  const helpDialogRef = useRef<HTMLDialogElement>(null);
  const dialogRef = useRef<DialogHandle>(null);
  const touchy = true;

  return <>
    <button
      className={css['clientControls']}
      onClick={() => {
        if (!helpDialogRef.current)
          throw new Error("Help dialog not defined");
        if (!helpDialogRef.current.open)
          helpDialogRef.current.show();
        else
          helpDialogRef.current.close();
      }}
    >
      {'\ue887'}
    </button>

    <button
      className={css['clientControls']}
      onClick={() => {
        dialogRef.current?.show();
      }}
    >
      {'\ue8b8'}
    </button >

    <Dialog
      ref={helpDialogRef}
      style={{
        margin: '0em 1em 0 auto',
        border: 'none',
        borderRadius: 16,
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
        width: 'fit-content',
        padding: '1rem'
      }}
      automaticallyCloseOnFocusLoss
    >
      <div className={css['helpContainer']}>
        <div className={css['desc']} >
          <b style={{ justifySelf: 'start' }} >Keyboard:</b>
          <label htmlFor='W'><b>W:</b> forwards</label>
          <label htmlFor='A'><b>A:</b> left</label>
          <label htmlFor='S'><b>S:</b> backwards</label>
          <label htmlFor='D'><b>D:</b> right</label>
          <label htmlFor='Q'><b>Q:</b> up</label>
          <label htmlFor='E'><b>E:</b> down</label>
        </div>
        <div className={css['keys']}>
          <input readOnly id='Q' value='Q' className={css['boring']} />
          <input readOnly id='W' value='W' />
          <input readOnly id='E' value='E' className={css['boring']} />
          <input readOnly id='A' value='A' />
          <input readOnly id='S' value='S' />
          <input readOnly id='D' value='D' />
        </div>


        <div className={css['desc']}>
          <b>Mouse:</b>
          <label htmlFor='MiddleMouse'>Use mouse scroll wheel to increase & decrease movement speed, hold down to move in & out.</label>
          <label htmlFor='LeftMouse'>Click & hold left mouse button to look up, down, left, or right.</label>
          <label htmlFor='RightMouse'>Hold right mouse button to pan around.</label>
        </div>
        <div
          className={css['mouse']}
          style={{
            placeSelf: 'center',
            display: 'grid',
            gridTemplateColumns: '2rem auto 2rem',
            gridTemplateRows: '2.25rem 4rem',
            borderWidth: 2,
            width: 'max-content',
            textAlign: 'center',
          }} >
          <input id='LeftMouse' readOnly style={{
            borderTopLeftRadius: 18,
            borderBottomWidth: 2
          }} />
          <div>
            <input
              id='MiddleMouse'
              readOnly
              style={{
                height: 16,
                width: 6,
                borderRadius: 8,
                borderWidth: 2,
                margin: 'auto -40px',
                top: 6,
                position: 'relative'
              }} />
          </div>
          <input id='RightMouse' readOnly style={{
            borderTopRightRadius: 18,
            borderBottomWidth: 2
          }} />
          <b style={{
            gridColumn: 'span 3',
            borderWidth: 2,
            borderTopWidth: 0,
            borderBottomLeftRadius: 30,
            borderBottomRightRadius: 30,
          }} />
        </div>

        {touchy && <>
          <div className={css['desc']}>
            <b>Touchscreen:</b>
            <label htmlFor='SingleFinger'>Single finger drag: look up, down & turn left, right.</label>
            <label htmlFor='TwoFingerPinch'>Two finger pinch/zoom: move forward, backward. </label>
            <label htmlFor='TwoFingerDrag'>Two finger drag: pan up, down, left, right.</label>
          </div>
          <div />
        </>}

        <div className={css['desc']}>
          <b>XR:</b>
          <div style={{ display: 'grid' }} >
            <label htmlFor='left-thumb'>Left thumbstick: move forward, backward, left, right</label>
            <label htmlFor='right-thumb'>Right thumbstick: move up, down & turn left, right</label>
            <label htmlFor='left-grip'>Left controller grip: decrease movement speed</label>
            <label htmlFor='right-grip'>Right controller grip: increase movement speed</label>
          </div>
        </div>
        <div className={css.xrVisual}>
          <Controller left />
          <Controller right />
        </div>
      </div>

    </Dialog>

    <Dialog ref={dialogRef} modal title="Settings">
      <Settings />
      <AnimationControls />
    </Dialog>
  </>;
}
