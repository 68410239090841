import { useContext } from 'react';
import TreeContext from './TreeContext';
import css from './TreeNodeLabel.module.scss';

interface TreeNodeLabelProps {
  id: number,
  name: string,
}

export default function TreeNodeLabel({ id, name }: TreeNodeLabelProps) {
  const context = useContext(TreeContext);
  const blinkTo = context?.blinkTo;
  return (
    <span
      onClick={(e) => { e.stopPropagation(); }}
      onDoubleClick={() => { if (blinkTo) { blinkTo(id); } }}
      className={css["node-text"]}
      tabIndex={0}
    >
      {name}
    </span>
  );
}