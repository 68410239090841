import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import css from './Card.module.scss';

interface CardThemeProps {
  theme?: 'basic';
  layout?: 'standard' | 'wide';
}

export default function CardList({
  theme,
  layout,
  children,
  className,
  style = {
    display: "grid",
    gap: layout === 'standard' ? 16 : 8,
    gridTemplateColumns: layout === 'standard' ? 'repeat(auto-fit, minmax(250px, 1fr))' : undefined
  },
  ...props
}: PropsWithChildren<CardThemeProps & ComponentPropsWithoutRef<'div'>>) {
  return <div {...props} style={style} className={`${css['card-list']} ${css[`layout-${layout ?? 'standard'}`]} ${css[`style-${theme ?? 'basic'}`]} ` + (className ?? '')} >
    {children}
  </div>;
}