import { useState, useEffect, type PropsWithChildren, type ReactNode, type ComponentPropsWithoutRef } from 'react';
import css from './Panel.module.scss';

const lockStateValue = 'enabled';

interface PanelProps extends Omit<ComponentPropsWithoutRef<'div'>, 'title' | 'tabIndex'> {
  lockKey?: string;
  title?: ReactNode;
  alignment: 'left' | 'right';
}

export default function Panel({ children, lockKey, title, className, alignment, ...props }: PropsWithChildren<PanelProps>) {
  const [locked, setLocked] = useState(false);

  useEffect(() => {
    // As we're rendering using SSR we'll defer reading the cached lock value
    setLocked(!!lockKey && (localStorage?.getItem(lockKey) == lockStateValue));
  }, [lockKey, setLocked]);

  return (
    <div className={`${css["panel"]} ${css[alignment]} ${locked && css["locked"]} ${className}`} tabIndex={-1} {...props} >
      <div className={css["panel-title"]}>
        <span style={{
          gridArea: 'content',
        }} >{title ?? ''}</span>
        <a
          style={{
            cursor: 'pointer',
            gridArea: alignment,
            textAlign: alignment,
            userSelect: 'none',
            color: 'var(--ui-orange)',
            margin: 4
          }}
          onClick={() => {
            setLocked(lock => {
              lock = !lock;
              if (localStorage && lockKey) {
                if (lock)
                  localStorage.setItem(lockKey, lockStateValue);
                else
                  localStorage.removeItem(lockKey);
              }
              return lock;
            });
          }}>
          {locked ? '\u{e897}' : '\u{f656}'}
        </a>
      </div>
      <div className={css["content"]}>
        {children}
      </div>
    </div>
  );
}
