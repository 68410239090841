import type { MouseEventHandler } from "react";
import css from './Controls.module.scss';

export default function InputPlaceholder({ placeholder, onClick, title }: {
  placeholder?: string;
  title?: string;
  onClick?: MouseEventHandler;
}) {

  return <textarea
    required
    value=''
    title={title}
    placeholder={placeholder??' '}
    onClick={onClick}
    onChange={(e) => { e.currentTarget.setCustomValidity(''); return false; }}
    className={`${css['control']} ${css['blockingPlaceholder']} ${!placeholder ? css['hidden']:''}`}
    style={onClick ? { cursor: 'pointer' } : { borderColor: 'var(--control-border)' }}
    onInvalid={title ? (e) => { e.currentTarget.setCustomValidity(title); } : undefined}
  />;
}