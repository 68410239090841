import Input from "../../src/components/Input";
import css from './General.module.scss';
import Select from "../../src/components/Select";
import ProjectContext, { isPublishAction, generateActionsWithUpdatedPublishTitle } from "./ProjectContext";
import { useContext, useEffect, useState } from "react";

export default function Publish() {
  const project = useContext(ProjectContext);
  const [publishName, setPublishName] = useState<string>();
  const [publishTrigger, setPublishTrigger] = useState<string>();
  const [publishHour, setPublishHour] = useState<number>();

  useEffect(() => {
    if (publishName === undefined)
      return;

    const updatedActions = generateActionsWithUpdatedPublishTitle(project, project.name ?? 'UNKNOWN_NAME');
    project.setProjectState({ ...project,  actions: updatedActions });
  }, [project, publishName]);

  useEffect(() => {
    if (publishTrigger === undefined && publishHour === undefined)
      return;

    project.setProjectState({
      ...project,
      ...publishTrigger !== undefined ? { trigger: publishTrigger } : {},
      ...publishHour !== undefined ? { hourToPublish: publishHour } : {},
    });
  }, [project, publishHour, publishTrigger]);

  return <div className={css['controls']} >
    <label style={{ display: 'grid' }}>
      <strong>Name</strong>
      <Input
        type="text"
        required
        defaultValue={project.actions.find(isPublishAction)?.name ?? ''}
        onChange={e => { setPublishName(e.target.value); }}
      />
    </label>
    <h3>Publication schedule</h3>
    <div style={{ display: 'flex', alignItems: 'end' }} >
      <label style={{ display: 'grid', flexGrow: 1 }}>
        <strong>Schedule</strong>
        <Select onChange={e => { setPublishTrigger(e.target.value); }} defaultValue={project.trigger} >
          <option value='manual'>Manual</option>
          <option value='daily'>Daily at specific hour</option>
          <option value='data_changed'>When the source data changes</option>
        </Select>
      </label>
      {
        (publishTrigger ?? project.trigger) === 'daily' &&
        <Select
          defaultValue={project.hourToPublish}
          onChange={(e) => { setPublishHour(parseInt(e.target.value)); }}
        >
          {Array(24).fill(0).map((_, index) =>
            <option key={index} value={index}>{index}:00</option>
          )}
        </Select>
      }
    </div>
  </div>;
}
