import type { ReadonlyVec3 } from 'gl-matrix';
import { type Dispatch, type SetStateAction, createContext } from 'react';
import ConnectionState from '../../src/types/ConnectionState';
import type {DeviceType} from '../../src/types/DeviceType';

type DispatchStatus<T> = null | Dispatch<SetStateAction<T>>;

export type SetDeviceCallback = { handle?: (device: DeviceType) => void; };
export type SetDebugAnimationCallback = {
  handle?: (animSettings: { debugAnimationRotationDuration: number,
    debugAnimationDelayTime: number,
    debugAnimationZoomTime: number,
    debugAnimationNear: number,
    debugAnimationFar: number,
    debugAnimationPosX: number,
    debugAnimationPosY: number,
    debugAnimationPosZ: number,
  } | null) => void;
};
export type SetSettingsCallback = { handle?: (settings: {
  backfaceCullingEnabled: boolean,
  levelOfDetail: number,
} | null) => void;
}
export type BlinkToCallback = { handle?: (box: [ReadonlyVec3, ReadonlyVec3], view: boolean) => void; }
export type RemoteFuncCall = { handle?: VoidFunction; }
export interface GetDevMonitorState {
  active: boolean;
}

interface ControlContext {
  setTimings: DispatchStatus<ReadonlyVec3>;
  setTimingsCallback: (v: DispatchStatus<ReadonlyVec3>) => void;

  setConnectionStatus: DispatchStatus<ConnectionState>;
  setConnectionStatusCallback: (v: DispatchStatus<ConnectionState>) => void;

  setMeshesPending: DispatchStatus<number>;
  setMeshesPendingCallback: (v: DispatchStatus<number>) => void;

  setDrawablesPending: DispatchStatus<number>;
  setDrawablesPendingCallback: (v: DispatchStatus<number>) => void;

  setBroadOccluderCount: DispatchStatus<number>;
  setBroadOccluderCountCallback: (v: DispatchStatus<number>) => void;

  setDrawCallCount: DispatchStatus<number>;
  setDrawCallCountCallback: (v: DispatchStatus<number>) => void;

  setTriangleCount: DispatchStatus<number>;
  setTriangleCountCallback: (v: DispatchStatus<number>) => void;

  setDevice: DispatchStatus<SetDeviceCallback>;
  setDeviceCallback: (v: DispatchStatus<SetDeviceCallback>) => void;

  setDebugAnimation: DispatchStatus<SetDebugAnimationCallback>;
  setDebugAnimationCallback: (v: DispatchStatus<SetDebugAnimationCallback>) => void;

  setParticipationID: DispatchStatus<string>;
  setParticipationIDCallback: (v: DispatchStatus<string>) => void;

  setSettings: DispatchStatus<SetSettingsCallback>;
  setSettingsCallback: (v: DispatchStatus<SetSettingsCallback>) => void;

  setBlinkTo: DispatchStatus<BlinkToCallback>;
  setBlinkToCallback: (v: DispatchStatus<BlinkToCallback>) => void;

  setFadeIn: DispatchStatus<RemoteFuncCall>;
  setFadeInCallback: (v: DispatchStatus<RemoteFuncCall>) => void;

  statsMonitor: GetDevMonitorState | null;
  setStatsMonitor: (v: GetDevMonitorState | null) => void;
}

function ControlContextStub() {
  console.warn("Called control context dummy function");
}

const ControlContext = createContext<ControlContext>({
  setTimings: null,
  setTimingsCallback: ControlContextStub,

  setConnectionStatus: null,
  setConnectionStatusCallback: ControlContextStub,

  setMeshesPending: null,
  setMeshesPendingCallback: ControlContextStub,

  setDrawablesPending: null,
  setDrawablesPendingCallback: ControlContextStub,

  setBroadOccluderCount: null,
  setBroadOccluderCountCallback: ControlContextStub,

  setDrawCallCount: null,
  setDrawCallCountCallback: ControlContextStub,

  setTriangleCount: null,
  setTriangleCountCallback: ControlContextStub,

  setDevice: null,
  setDeviceCallback: ControlContextStub,

  setDebugAnimation: null,
  setDebugAnimationCallback: ControlContextStub,

  setParticipationID: null,
  setParticipationIDCallback: ControlContextStub,

  setSettings: null,
  setSettingsCallback: ControlContextStub,

  setBlinkTo: null,
  setBlinkToCallback: ControlContextStub,

  setFadeIn: null,
  setFadeInCallback: ControlContextStub,

  statsMonitor: null,
  setStatsMonitor: ControlContextStub,
});

export default ControlContext;