import { createContext } from "react";

export interface AddDataAction {
  readonly shortName: string;
  readonly vrmodel: string;
  readonly type: "ADD_DATA";
}
export interface TemplateAction {
  readonly templateString: string;
  readonly template_id: string;
  readonly type: "APPLY_TEMPLATE";
}
export interface PublishAction {
  readonly name: string;
  readonly type: "PUBLISH";
  readonly audience: ReadonlyArray<string>;
}

type Actions = PublishAction | TemplateAction | AddDataAction;

export function isAddDataAction(v: Actions): v is AddDataAction {
  return v.type === 'ADD_DATA';
}
export function isTemplateAction(v: Actions): v is TemplateAction {
  return v.type === 'APPLY_TEMPLATE';
}
export function isPublishAction(v: Actions): v is PublishAction {
  return v.type === 'PUBLISH';
}

export interface ProjectMetaData {
  readonly uuid: string;
  readonly name: string;
  readonly headVersion: {
    readonly revision: number;
    readonly previousRevision: number;
    readonly previousRevisionProject: null;
    readonly createdDate: string;
    readonly publishedDate: null;
    readonly author: null;
  };
  readonly labels: ReadonlyArray<unknown>;
  readonly isDraft: false;
  readonly publishedJobs: null;
  readonly activeJobs: null;
  readonly publishedVisualisations: null;
}

export interface IProjectState {
  readonly name: string;
  readonly hourToPublish: number;
  readonly groups: ReadonlyArray<string>;
  readonly trigger: string;
  readonly actions: ReadonlyArray<PublishAction | TemplateAction | AddDataAction>;
  readonly allowScripts: boolean;
}

export interface ProjectContextState extends IProjectState {
  readonly setProjectState: (project: IProjectState) => void;
}

const ProjectContext = createContext<ProjectContextState>(null as unknown as ProjectContextState);

export function generateActionsWithUpdatedPublishTitle(project: IProjectState, projectName: string): Actions[] {
  let actions = [...project.actions];
  // get original position of first available publishing action (if present)
  const insertPosition = actions.findIndex(isPublishAction);
  // new publish action instance
  const publishAction: PublishAction = {
    name: projectName,
    ...(insertPosition >= 0 ? { audience: (actions[insertPosition] as PublishAction).audience } : { audience: [] }),
    type: 'PUBLISH'
  };
  // remove any publishing actions ready to insert our own
  actions = actions.filter(v => !isPublishAction(v));
  // insert at original position or at the end of the actions array
  actions.splice(insertPosition >= 0 ? insertPosition : actions.length, 0, publishAction);
  return actions;
}

export default ProjectContext;