import { useContext, useEffect, useRef } from 'react';
import ControlContext from './ControlContext';
import Participate from '../Participate';
import { ActorType } from '../../src/types/ParticipationInfo';
import PageContext from '../PageContext';
import type { ReadonlyVec3 } from 'gl-matrix';

export default function Main() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const user = useContext(PageContext);
  const {
    setTimings,
    setConnectionStatus,
    setMeshesPending,
    setDrawablesPending,
    setBroadOccluderCount,
    setDrawCallCount,
    setTriangleCount,
    setDevice,
    setDebugAnimation,
    setParticipationID,
    setSettings,
    setBlinkTo,
    setFadeIn,
  } = useContext(ControlContext);


  useEffect(() => {
    if (!user)
      return;

    const canvas = canvasRef.current;
    if (!canvas)
      return;

    const abort = new AbortController();
    const initializeNetApi = async () => {
      const { run } = await import('./client');
      if (abort.signal.aborted)
        return null;

      console.log("Creating culler participation!");
      const participation = await Participate(user, abort.signal, ActorType.Culling);
      const parameters = new URLSearchParams();
      if (participation.participationId)
        parameters.append('participant', participation.participationId);
      const searchParams = parameters.toString();
      const host = participation.cullerHost + (searchParams ? `?${searchParams}` : '');
      console.log(`Culling actor host: ${host}`);

      const api = run(user, participation, canvas, (avg, min, max) => {
        if (setTimings)
          setTimings(v => abort.signal.aborted ? v : [avg, min, max] satisfies ReadonlyVec3);
      });

      api.onConnectionStatusUpdate = setConnectionStatus;
      api.onMeshProgress = setMeshesPending;
      api.onDrawableProgress = setDrawablesPending;
      api.onBroadOccluderCountChange = setBroadOccluderCount;
      api.onDrawCallCountChange = setDrawCallCount;
      api.onTriangleCountChange = setTriangleCount;
      api.participationID = setParticipationID;

      if (setBlinkTo)
        setBlinkTo({ handle: api.blinkTo.bind(api) });
      if (setFadeIn)
        setFadeIn({ handle: api.fadeIn.bind(api) });
      if (setDevice)
        setDevice(v => abort.signal.aborted ? v : { handle: api.requestDevice.bind(api) });
      if (setDebugAnimation)
        setDebugAnimation(v => abort.signal.aborted ? v : { handle: api.setDebugAnimation.bind(api) });
      if (setSettings)
        setSettings(v => abort.signal.aborted ? v : { handle: api.setSettings.bind(api) });

      return api;
    };

    const result = initializeNetApi();
    
    return () => {
      abort.abort();
      result.then(api => { if (api) api.dispose().catch(console.error); }).catch(() => { });
    };
  },
    [
      user,
      setTimings,
      setConnectionStatus,
      setMeshesPending,
      setDrawablesPending,
      setBroadOccluderCount,
      setDrawCallCount,
      setDevice,
      setDebugAnimation,
      setSettings,
      setTriangleCount,
      setParticipationID,
      setBlinkTo,
      setFadeIn,
    ]
  );

  return <canvas
    ref={canvasRef}
    tabIndex={-1}
    style={{
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: "100%",
      outline: 'none',
      zIndex: -1,
      touchAction: 'none'
    }}
  />;
}
