import { createContext } from "react";
import type Profile from "./types/Profile";
import TranslationsSource from './Translations.json'
import type UserAuth from "./types/UserAuth";

const firstTranslation = Object.values(TranslationsSource)[0];
type Locale = keyof typeof firstTranslation;

interface IPageContext extends UserAuth, Profile {
  readonly profileUrl: () => string;
  readonly logout: VoidFunction;
  readonly locale?: Locale;
}

const PageContext = createContext<IPageContext | null>(null);

export default PageContext;