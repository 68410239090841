import { DateTime } from 'luxon';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FaPlus } from "react-icons/fa";
import PageContext from '../PageContext';
import HubAside from '../components/HubAside';
import AutoFetch from '../utils/AutoFetch';
import Card from '../../src/components/Card';
import CardList from '../../src/components/CardList';
import PageHeader from '../../src/components/PageHeader';
import Input from '../../src/components/Input';
import Select from '../../src/components/Select';
import Button from '../../src/components/Button';
import Alert from '../../src/components/Alert';
import Spinner from '../../src/components/Spinner';
import Pagination from '../../src/components/Pagination';
import PaginationWrapper from '../../src/components/PaginationWrapper';
import PageContent from '../../src/components/PageContent';

enum ProjectOrderBy {
  CreatedDate,
  NameASC,
  NameDESC
}

interface IProjects {
  totalResults: number;
  enhancedProjects: {
    uuid: string;
    name: string;
    headVersion: {
      revision: number;
      createdDate: string;
    };
  }[];
}

// auto refreshing can be irritating,
// just when you go to click a link
// the results shift down the page
const fetchConfig = {
  retryCount: 3,
  refreshDelay: 10000
};
const PAGE_SIZE = 10;

export default function ProjectsPage() {
  const user = useContext(PageContext);
  const [orderByInt, setOrder] = useState<ProjectOrderBy>(ProjectOrderBy.CreatedDate);
  const [error, setError] = useState('');
  const [searchString, setSearchString] = useState('');
  const [projects, setProjects] = useState<IProjects>();
  const [fetching, setFetching] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pendingQuery, setPendingQuery] = useState<URLSearchParams>();
  const [currentQuery, setCurrentQuery] = useState<URLSearchParams>();

  useEffect(() => {
    // if we've changed the name filter or ordering
    // set us back to page 1 if we're not already on it
    let pageNumber = currentPage;
    if (
      pendingQuery &&
      pendingQuery?.get("PageNumber") !== "1" &&
      (
        pendingQuery.get("ProjectNameFilter") !== searchString ||
        (pendingQuery.get("SortBy") === 'createdDate' && orderByInt !== ProjectOrderBy.CreatedDate) ||
        (pendingQuery.get("SortOrder") === 'ASC' && orderByInt !== ProjectOrderBy.NameASC)
      )) {
      pageNumber = 1;
      setCurrentPage(1);
    }

    const query = new URLSearchParams({
      SortBy: orderByInt === ProjectOrderBy.CreatedDate ? 'createdDate' : 'name',
      SortOrder: orderByInt === ProjectOrderBy.NameASC ? 'ASC' : 'DESC',
      PageNumber: pageNumber.toString(),
      PageSize: PAGE_SIZE.toString(),
      ProjectNameFilter: searchString
    });

    // Only set the query if it is different from what we've already set
    if (query.toString() !== pendingQuery?.toString())
      setPendingQuery(query);
  }, [currentPage, currentQuery, orderByInt, pendingQuery, searchString]);

  useEffect(() => {
    if (!pendingQuery)
      return;

    let aborted = false;
    setError(v => aborted ? v : '');
    setFetching(v => aborted ? v : true);
    let initialResultsAcquired = false;

    const cancelFetch = AutoFetch(
      `hub/api/projects?${pendingQuery.toString()}`,
      () => ({ headers: { 'Authorization': `Bearer ${user?.access_token}` } }),
      (data) => {
        initialResultsAcquired = true;
        setCurrentQuery(v => aborted ? v : pendingQuery);
        setFetching(v => aborted ? v : false);
        setProjects(v => aborted ? v : data as IProjects);
      },
      () => {
        setError(v => aborted ? v : `Unable to ${initialResultsAcquired ? 'refresh' : 'retrieve'} the projects list.`);
      },
      fetchConfig);

    return () => {
      aborted = true;
      cancelFetch();
    };
  }, [pendingQuery, user]);

  const deleteProject = useCallback((uuid: string) => {
    fetch(`hub/api/projects/${uuid}`, {
      headers: { 'Authorization': `Bearer ${user?.access_token}` },
      method: 'DELETE'
    }).then(() => {
      setProjects(projects => (projects ? {
        enhancedProjects: projects?.enhancedProjects.filter(a => a.uuid !== uuid),
        totalResults: projects?.totalResults - 1
      } : projects));
      //TODO: trigger re-query as it _might_ end up returning the now deleted project
    }).catch(setError);
  }, [user]);

  return <PageContent>
    <HubAside current='projects' />
    <PageHeader
      title={("Projects")}
      subTitle={("Configure how data is processed to create visualisations")}
    >
      <div
        style={{
          display: 'flex',
          gap: 4,
          alignContent: 'center',
          flexGrow: 1,
          maxWidth: 'max-content',
          width: 'min-content',
          flexWrap: 'wrap',
        }}
      >
        <div style={{
          display: 'flex',
          gap: '0.5em',
          flexGrow: 1,
        }} >
          <label style={{ marginTop: 'auto', marginBottom: 'auto', whiteSpace: 'nowrap', fontWeight: 'bold' }}>Order by</label>
          <Select
            style={{ flexGrow: 1 }}
            value={orderByInt}
            onChange={e => setOrder(parseInt(e.target.value))}>
            <option value={ProjectOrderBy.CreatedDate}>Created date</option>
            <option value={ProjectOrderBy.NameASC}>{'Name \u{2191}'}</option>
            <option value={ProjectOrderBy.NameDESC}>{'Name \u{2193}'}</option>
          </Select>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '0.5em',
            flexGrow: 1,
          }}>
          <Input
            style={{ flexGrow: 1 }}
            size={14}
            type='search'
            placeholder='Search for project'
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
          />
          <Button
            href='/create'
            primary
            title='Create a new project'
          >
            <FaPlus />
            {'\u{00A0}'}
            <span>New project</span>
          </Button>
        </div>
      </div>
    </PageHeader>

    <div style={{ margin: '0px 24px' }} >
      {error && <Alert severity="warning">{`${error}. Please refresh the page to try again.`}</Alert>}

      <div style={{ display: 'flex', alignItems: 'center' }} >
        <h2>{
          (currentQuery?.get('ProjectNameFilter') ?? '').length > 0
            ? <>Projects containing {'\u{27}'}{currentQuery?.get('ProjectNameFilter')}{'\u{27}'}</>
            : <>All projects</>}</h2>
        {'\u{00A0}'}
        {fetching && !error && <Spinner />}
      </div>

      <CardList layout='wide'>
        {projects?.enhancedProjects?.map((p) =>
          <Card
            key={p.uuid}
            header={p.name}
            actions={
              <>
                <Button
                  href={`/edit?id=${p.uuid}`}
                  title='Edit this project'
                >
                  Edit
                </Button>
                {'\u{00A0}'}
                <Button
                  title='Delete this project'
                  onClick={() => { deleteProject(p.uuid); }}
                >
                  Delete
                </Button>
              </>
            }
          >
            {p.headVersion &&
              <>
                {'Project '}
                {p.headVersion.revision > 1 ? 'last updated' : 'created'}
                {' on '}
                {DateTime.fromISO(p.headVersion.createdDate).toLocaleString(DateTime.DATETIME_MED)}
              </>}
          </Card>)}
      </CardList>
    </div>
    {projects && projects.totalResults > PAGE_SIZE &&
      <footer>
      <PaginationWrapper>
        <Pagination
          onPageRequest={setCurrentPage}
          currentPage={parseInt(currentQuery?.get('PageNumber') ?? '0')}
          totalPageCount={Math.ceil(projects.totalResults / PAGE_SIZE)}
        />
        </PaginationWrapper>
      </footer>
    }
  </PageContent>;
}
