import { type MouseEventHandler, useCallback, useRef, type PropsWithChildren, type ReactElement, useContext, type ComponentPropsWithoutRef } from 'react';
import { MdContentCopy } from 'react-icons/md';
import ToastContext from './ToastContext';

interface CopyLinkProps extends ComponentPropsWithoutRef<'div'> {
  uri: string;
  caption?: string;
  innerClassName?: string;
  linkCopiedMessage: string;
  linkCopyFailMessage: string;
  icon?: string | ReactElement | boolean;
  ariaLabel?: string;
}

export default function CopyLink(props: PropsWithChildren<CopyLinkProps>) {
  const { icon, caption, uri, innerClassName, ariaLabel, children, linkCopiedMessage, linkCopyFailMessage, ...extendedAttributes } = props;
  const m_linkRef = useRef<HTMLDivElement>(null);
  const toaster = useContext(ToastContext);

  const copyToClipboard = useCallback<MouseEventHandler>((e) => {
    e.preventDefault();

    navigator.clipboard.writeText(uri)
      .then(() => {
        toaster?.showToast(linkCopiedMessage, {
          type: 'success'
        });
      }).catch(() => {
        toaster?.showToast(linkCopyFailMessage, {
          type: 'error'
        });
      });
  }, [linkCopiedMessage, linkCopyFailMessage, toaster, uri]);

  const iconElem =
    typeof icon === 'boolean' ? icon && <MdContentCopy /> :
      typeof icon === 'string' ? <img src={icon} alt="" className="p-icon" /> :
        icon;

  return (
    <div aria-label={ariaLabel} onClick={copyToClipboard} ref={m_linkRef} {...extendedAttributes} >
      {iconElem}
      {caption && (
        <div className={innerClassName}>
          {caption}
          {children}
        </div>
      )}
    </div>
  );
}
