import { useContext, useState, type Dispatch, type PropsWithChildren, type SetStateAction } from "react";
import Input from "../../src/components/Input";
import PageContext from "../PageContext";
import Alert from "../../src/components/Alert";
import FieldSet from "../../src/components/FieldSet";

type GroupType = ReturnType<typeof useState<ReadonlySet<string>>> | [ReadonlySet<string>, Dispatch<SetStateAction<ReadonlySet<string>>>];

export default function GroupSelector({
  children,
  ...props
}: PropsWithChildren<{
  selectedGroupState: GroupType;
} | { name: string; }>) {
  const user = useContext(PageContext);
  // used as a fallback if not providing a state provider via props
  const internalGroupSelector = useState<ReadonlySet<string>>();
  const [selectedGroups, setSelectedGroups] = 'selectedGroupState' in props ? props.selectedGroupState : internalGroupSelector;
  const [isPrivate, setPrivate] = useState(!selectedGroups || selectedGroups.size === 0);
  const name = 'name' in props ? props.name : undefined;

  return <FieldSet style={{ display: 'flex', gap: '1em 2em', flexWrap: 'wrap', alignItems: 'flex-start' }}>
    {children && <legend>{children}</legend>}
    <div>
      <div style={{ display: 'grid' }} onChange={(e) => {
        if (!(e.target instanceof HTMLInputElement))
          return;

        if (parseInt(e.target.value)) {
          setSelectedGroups(new Set());
          setPrivate(true);
        } else {
          setPrivate(false);
        }
      }}>
        <label>
          <Input type="radio" value={1} checked={isPrivate} onChange={()=>undefined} />
          {`\u{00A0}`}Only me
        </label>
        <label>
          <Input type="radio" value={0} checked={!isPrivate} onChange={() => undefined} />
          {`\u{00A0}`}Me and groups I have invited
        </label>
      </div>
      {!isPrivate &&

        <div style={{ textIndent: '1.15em', display: 'grid' }}>
          {user?.groups?.map((group) =>
            <label key={group} >
              <Input
                type="checkbox"
                name={name}
                value={group}
                checked={selectedGroups?.has(group)}
                onChange={(e) => {
                  setSelectedGroups((v?: ReadonlySet<string>) => {
                    const result = new Set(v ?? selectedGroups);
                    if (e.target.checked)
                      result.add(group);
                    else
                      result.delete(group);
                    return result;
                  });
                }}
              />
              {`\u{00A0}${group}`}
            </label>
          )}
        </div>
      }
    </div>
    {!isPrivate && (!selectedGroups || selectedGroups?.size === 0) &&
      <Alert style={{ flexGrow: 1 }} severity='error'>Please specify at least one group to share to</Alert>
    }
  </FieldSet>;
}