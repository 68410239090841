import { startTransition, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import PageContent from "../../../src/components/PageContent";
import PageHeader from "../../../src/components/PageHeader";
import Spinner from "../../../src/components/Spinner";
import HubAside from "../../components/HubAside";
import Button from "../../../src/components/Button";
import UploadSuccess from "../UploadSuccess";
import DropZone from "../DropZone";
import type { FileState, FileStatePending, FileStateUploading } from "../FileState";
import UploadChunked from "../UploadChunked";
import PageContext from "../../PageContext";
import AcquireUploadFolder from "../AcquireUploadFolder";
import MainFileSelector from "../MainFileSelector";
import GroupSelector from "../../components/GroupSelector";
import InputPlaceholder from "../../../src/components/InputPlaceholder";

function isFilePending(v: [string, FileState]): v is [string, FileStatePending] { return v[1].type === 'pending'; }
function isFileUploading(v: [string, FileState]): v is [string, FileStateUploading] { return v[1].type === 'uploading'; }

const MAX_CONCURRENT_FILE_UPLOADS = 3;

export default function UpdatePage() {
  const assetId = new URLSearchParams(location.search).get('id');

  const [submitState, setSubmitState] = useState<'clearing' | 'configuring' | 'ready' | 'submitting' | 'complete'>('configuring');
  const [folder, setFolder] = useState<string>();
  const [files, setFiles] = useState<ReadonlyMap<string, FileState>>();
  const user = useContext(PageContext);
  const updateFormRef = useRef<HTMLFormElement>(null);
  const [bytesUploaded, setBytesUploaded] = useState(0);
  const byteTotal = useMemo(() => {
    if (!files)
      return 0;
    return [...files].reduce((n, [, v]) => (n + v.file.size), 0);
  }, [files]);

  const clearFolder = useCallback(async function () {
    if (!user || !folder)
      return;
    return fetch(`/hub/api/Folder/${folder}`, {
      headers: { 'Authorization': `Bearer ${user.access_token}` },
      method: 'DELETE'
    }).then(response => response.ok);
  }, [user, folder]);

  useEffect(() => {
    if (!user || !folder || submitState !== 'clearing')
      return;

    setFiles(v => {
      if (v)
        for (const [, f] of v)
          if (f.type === 'uploading' && f.abort)
            f.abort("ClearingFolder");
      return undefined;
    });

    clearFolder().catch(() => false).finally(() => {
      setFolder(undefined);
      setSubmitState('configuring');
    });
  }, [clearFolder, folder, submitState, user]);

  useEffect(() => {
    if (!user)
      return;
    if (!folder)
      AcquireUploadFolder(user)
        .then(({ folderId, containedFiles }) => {
          startTransition(() => {
            setFolder(folderId);
            setBytesUploaded(0);
            setFiles(containedFiles.length
              ? new Map(containedFiles.map(v => [v, { type: 'completed', file: { size: 0 } }]))
              : undefined);
          });
        }).catch(console.warn);
  }, [folder, user]);

  // starts upload process of files of type 'uploading'
  useEffect(() => {
    if (!files || !folder || !user)
      return;

    const uploadingFiles = new Map([...files].filter(isFileUploading));

    for (const [path, uploading] of uploadingFiles) {
      if (uploading.abort)
        continue;

      let uploaded = 0;
      const controller = new AbortController();
      UploadChunked(
        uploading.file,
        folder,
        user,
        (p) => {
          uploaded += p;
          setBytesUploaded(v => v + p);
        },
        controller.signal)
        .then(() => {
          setFiles(v => new Map([...v ?? []].map(v => v[0] === path
            ? [path, { type: 'completed', file: { size: uploaded } }]
            : v)));
        })
        .catch(() => {
          // if we've aborted due to a page refresh, just let it drop out of scope
          if (controller.signal.aborted && controller.signal.reason === 'ClearingFolder')
            return;
          setBytesUploaded(v => v - uploaded);
          setFiles(v => new Map([...v ?? []].map(v => v[0] === path
            ? [path, { file: uploading.file, error: 'An issue occured during upload, retrying', type: 'pending' }]
            : v)));
        });

      uploading.abort = controller.abort.bind(controller);
    }
  });

  // transitions pending files to upload
  useEffect(() => {
    if (!files || !folder || !user)
      return;

    const thingTest = Math.random() * 65535;
    setFiles(files => {
      if (!files)
        return files;

      const uploadingFiles = new Map([...files].filter(isFileUploading));

      console.log(`${thingTest} uploads`, ...uploadingFiles.keys());
      if (uploadingFiles.size > MAX_CONCURRENT_FILE_UPLOADS)
        return files;

      // only grab remainder of concurrent uploads
      // note: sorted so that smallest files get uploaded first
      const currentlyPending = new Set([...files]
        .filter(isFilePending)
        .sort((a, b) => a[1].file.size - b[1].file.size)
        .map(v => v[0])
        .slice(0, MAX_CONCURRENT_FILE_UPLOADS - uploadingFiles.size));
      console.log(`${thingTest} pending`, ...currentlyPending.keys());

      // there's no pending items to upload
      if (!currentlyPending.size) {
        return files;
      }

      // transition pending items to upload state
      const result = new Map([...files].map((v) => {
        if (!isFilePending(v) || !currentlyPending.has(v[0]))
          return v;
        const path = v[0];
        const file = v[1].file;

        return [
          path,
          {
            file,
            type: 'uploading'
          }
        ] satisfies [string, FileStateUploading];
      }));

      console.log(`${thingTest} result`, result);
      return result;
    });

  }, [files, folder, submitState, user]);

  useEffect(() => {
    if (submitState !== 'ready' || !files || !user || [...files].some(([, v]) => { return v.type !== 'completed'; }))
      return;
    // automatically call submit when all data is ready
    if (!updateFormRef.current)
      throw new Error("Attempted to submit, but unable to find form element");
    setSubmitState('submitting');
  }, [submitState, files, user]);

  useEffect(() => {
    if (submitState !== 'submitting' || !user)
      return;
    // automatically call submit when all data is ready
    if (!updateFormRef.current)
      throw new Error("Attempted to submit, but unable to find form element");

    const controller = new AbortController();
    fetch('hub/api/Folder/submit', {
      headers: { 'Authorization': `Bearer ${user.access_token}` },
      signal: controller.signal,
      method: 'post',
      body: new FormData(updateFormRef.current)
    })
      .then((res) => {
        if (!res.ok)
          throw new Error(`Failed to submit[${res.status}]: ${res.statusText}`);
        setSubmitState('complete');
      })
      .catch(console.warn);

    return () => { controller.abort(); };
  }, [files, submitState, user]);

  const ProgressDisplay = () => byteTotal > 0 && <>
    Uploaded
    <progress value={bytesUploaded} max={byteTotal}></progress>
    {Math.ceil(bytesUploaded / 1000000)}MB of {Math.ceil(byteTotal / 1000000)}MB
  </>;

  return <PageContent>
    <PageHeader title={assetId ? "Updating asset" : "Import asset"} />
    <HubAside current='assets' />
    {
      submitState === 'complete' ?
        <UploadSuccess /> :
        (!folder || submitState === 'clearing') ?
          <Spinner style={{ placeSelf: 'center' }}>
            Creating upload folder
          </Spinner> :
          <>

            <form
              ref={updateFormRef}
              style={{ padding: 16 }}
              id='update'
              onReset={() => {
                setSubmitState('clearing');
              }}
              onSubmit={(e) => {
                e.preventDefault();
                setSubmitState('ready');
                return false;
              }}
            >
              {assetId && <input type='hidden' value={assetId} name='update' />}
              <input type='hidden' value={folder} name='folderId' />

              <DropZone
                readOnly={submitState !== 'configuring'}
                onChange={(e) => {
                  if (!e.currentTarget.files)
                    return;
                  const files = [...e.currentTarget.files];

                  setFiles(v => {
                    return new Map([...files.map(file =>
                      [
                        `${file.webkitRelativePath}/${file.name}`.replace(/^\//, ''),
                        { file: file, type: 'pending' }
                      ] satisfies [string, FileState]
                    ), ...v ?? []]);
                  });
                }}
              >
                <p>Upload your asset or folder by dragging it here</p>
                <p><em>or click to browse</em></p>
                {(!files || files.size <= 0) &&
                  < InputPlaceholder title='You need to upload at least one file' />
                }
              </DropZone>

              {!assetId && <>
                <h4>Configure Access</h4>
                <p>Who can access this data?</p>
                <GroupSelector name='groups' />
              </>}

              {files && files.size &&
                <MainFileSelector files={files} readOnly={submitState !== 'configuring'} name='mainfile' />
              }
            </form>

            {submitState === 'configuring' && <footer style={{
              display: 'flex',
              backgroundColor: 'var(--header-background)',
              padding: 8,
              alignItems: 'center',
              gap: 8,
            }} >
              <Button onClick={() => {
                clearFolder()
                  .then(() => { location.pathname = 'library'; })
                  .catch(console.warn);
              }}>Cancel</Button>
              <ProgressDisplay />
              <div style={{ flexGrow: 1 }} ></div>
              <Button type='reset' form='update'>Clear Data</Button>
              <Button primary type='submit' form='update'>Next</Button>
            </footer>}

            {submitState !== 'configuring' &&
              <div
                style={{
                  position: 'fixed',
                  inset: 0,
                  backdropFilter: 'grayscale(1) brightness(0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }} >
                <div
                  style={{
                    backgroundColor: 'var(--header-background)',
                    border: 'solid',
                    padding: '1em',
                    borderRadius: '1em',
                    userSelect: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    boxShadow: 'black 0px 0px 48px -16px',
                  }}
                >
                  {(byteTotal > 0 && bytesUploaded !== byteTotal) ? <>
                    <p>Your files are being uploaded to the server.</p>
                    <p>Please keep this window open until the upload is complete.</p>
                    <ProgressDisplay />
                    <Button onClick={() => { setSubmitState('configuring'); }}>Cancel</Button>
                  </> :
                    <p>Almost finished...</p>
                  }
                </div>
              </div>}
          </>
    }
  </PageContent>;
}
