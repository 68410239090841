import { useContext, useEffect, useState } from "react";
import ControlContext from "./ControlContext";
import type { ReadonlyVec3 } from "gl-matrix";
import css from './FramerateMonitor.module.scss';

export default function FramerateMonitor() {
  const [timeDelta, setTimeDelta] = useState([0, 0, 0] as ReadonlyVec3);
  const [broadOccluderCount, setBroadOccluderCount] = useState(0);
  const [drawCallCount, setDrawCallCount] = useState(0);
  const [triangleCount, setTriangleCount] = useState(0);
  const [enabled, showDevMonitor] = useState(localStorage.getItem('devUI') === 'enabled');
  const {
    setBroadOccluderCountCallback,
    setDrawCallCountCallback,
    setTriangleCountCallback,
    setTimingsCallback,
    setStatsMonitor,
  } = useContext(ControlContext);

  useEffect(() => {
    setBroadOccluderCountCallback(() => setBroadOccluderCount);
    setDrawCallCountCallback(() => setDrawCallCount);
    setTriangleCountCallback(() => setTriangleCount);
    setTimingsCallback(() => setTimeDelta);

    setStatsMonitor(({
      get active() { return enabled; },
      set active(v: boolean) {
        if (v)
          localStorage.setItem('devUI', 'enabled');
        else
          localStorage.removeItem('devUI');
        showDevMonitor(v);
      }
    }));

    return () => {
      setBroadOccluderCountCallback(null);
      setDrawCallCountCallback(null);
      setTriangleCountCallback(null);
      setTimingsCallback(null);
      setStatsMonitor(null);
    };
  }, [setBroadOccluderCountCallback, setStatsMonitor, setDrawCallCountCallback, setTimingsCallback, setTriangleCountCallback, enabled]);

  return (
    <div className={css["devMonitor"] + (enabled ? ` ${css['enabled']}` : '')}>
      <b>FPS</b>
      <em>Avg</em>
      <em>Min</em>
      <em>Max</em>
      <em>{timeDelta[0].toFixed(0).padStart(3, '\u00a0')}</em>
      <em>{timeDelta[1].toFixed(0).padStart(3, '\u00a0')}</em>
      <em>{timeDelta[2].toFixed(0).padStart(3, '\u00a0')}</em>
      <div>
        <b>Drawcalls:</b>{drawCallCount}
        <b>Triangles:</b>{triangleCount.toFixed(0).padStart(7, '\u00a0')}
        <b>Occluders:</b>{broadOccluderCount}
      </div>
    </div>
  );
}
