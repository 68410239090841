import { useContext, useEffect, useState } from "react";
import PageContext from "../PageContext";
import Alert from "../../src/components/Alert";
import PageContent from "../../src/components/PageContent";
import Spinner from "../../src/components/Spinner";
import type ArtifactItem from "../types/ArtifactItem";

const collaborationSessionPath = import.meta.env.VIRTALIS_COLLABORATION_SESSION_PATH;
if (!collaborationSessionPath)
  throw new Error("SessionPath undefined");
if (!import.meta.env.VIRTALIS_SESSION_SERVER_HOSTNAME)
  throw new Error("Session server hostname not defined");
if (typeof import.meta.env.VIRTALIS_SESSION_SERVER_HOSTNAME !== 'string')
  throw new Error("Session server hostname not string type");
const sessionServerHostname = new URL(
  (import.meta.env.VIRTALIS_SESSION_SERVER_HOSTNAME).startsWith('/') ?
    `${location.origin}${import.meta.env.VIRTALIS_SESSION_SERVER_HOSTNAME}` :
    import.meta.env.VIRTALIS_SESSION_SERVER_HOSTNAME);
const artifactAccessPath = import.meta.env.VIRTALIS_ARTIFACT_ACCESS_PATH;

enum SessionStates {
  Unknown,
  Stopped,
  Starting,
  Ready
}

function isObject(v: unknown): v is object {
  return typeof v === 'object';
}
function isString(v: unknown): v is string {
  return typeof v === 'string';
}
function isNumber(v: unknown): v is number {
  return typeof v === 'number';
}

async function createNewSession(artifactName: string, artifactId: string, ownerUserId: string, accessToken: string) {
  const data = {
    artifactName,
    artifactId,
    sessionServerHostname: sessionServerHostname.hostname,
    sessionServerPort: 0,
    isPublic: true,
    isActive: true,
    ownerUserId
  };
  const response = await fetch(collaborationSessionPath, {
    method: "POST",
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok)
    throw new Error("Invalid response");

  const sessionId = await response.json().then((data) => {
    if (
      isObject(data) &&
      'sessionId' in data &&
      isString(data.sessionId)
    )
      return data.sessionId;
    throw new Error("Returned without valid sessionId");
  });

  return sessionId;
}

export default function LoadingPage() {
  const user = useContext(PageContext);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (!user)
      throw new Error("User not logged in!");
    const parameters = new URLSearchParams(location.search);
    const ownerUserId = user.sub ?? '';
    let aborted = false;
    const catchAndDisplayError = (e: unknown) => {
      setError(v => aborted ? v : (e instanceof Error ? e.message : e as string));
      setTimeout(() => { location.replace(`${location.origin}`); }, 5000);
    }


    async function waitForSession(sessionId: string) {
      // around here-ish we'll want to see what state the actors are in
      // while its starting we wait here
      // if its stopped let the user know something might have gone wrong
      // if its running then redirect to the client
      // pray we don't recive unknown
      let sessionState = SessionStates.Unknown;
      do {
        const response = await fetch(`${collaborationSessionPath}/state/session/${sessionId}`, {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${user?.access_token}`
          }
        });
        sessionState = await response.json().then(data => {
          if (isObject(data) && 'status' in data && isNumber(data.status))
            return data.status;
          throw new Error("Invalid return format when checking loading status");
        });
      } while (sessionState == SessionStates.Starting);

      if (sessionState == SessionStates.Ready) {
        location.replace(`${sessionServerHostname.href}?session=${sessionId}`);
      }
      else if (sessionState == SessionStates.Stopped) {
        throw new Error("Could not prepare session deployment");
      }
    }

    async function getArtifactNameAsync(artifactId: string): Promise<string> {
      return await fetch(`${artifactAccessPath}/GetArtifact/${artifactId}`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${user?.access_token}`
        }
      }).then((response) => {
        if (!response.ok)
          throw new Error(`Error ${response.status}: ${response.statusText}!`);
        return response.json();
      }).then((data: ArtifactItem) => {
        if (isObject(data) && 'name' in data)
          return data.name;
        throw new Error("Invalid return format when getting artifact name");
      });
    }

    const artifactId = parameters.get('artifactId');
    const sessionId = parameters.get('sessionId');
    if (sessionId) {
      if (artifactId)
        console.warn("ArtifactId supplied alongside sessionId, this is not required");
      waitForSession(
        sessionId
      ).catch(catchAndDisplayError);
    }
    else if (artifactId) {
      // A hack so I'm less likely to spam createNewSession multiple times on mount
      setTimeout(() => {
        if (aborted)
          return;

        getArtifactNameAsync(
          artifactId
        ).then(artifactName => {
          return createNewSession(
              artifactName,
              artifactId,
              ownerUserId,
              user.access_token
          ).then(sessionId => {
            if (aborted)
              return;

            return waitForSession(
              sessionId
            );
          }).catch(catchAndDisplayError);
        }).catch(catchAndDisplayError);
      }, 100);
    }
    else {
      catchAndDisplayError(new Error("No ArtifactID or SessionID has not been specified in URL"));
    }

    return () => { aborted = true; }
  }, [user]);

  return <PageContent>
    {error && <Alert severity='error'>{error}</Alert>}
    <Spinner style={{ placeSelf: 'center' }}>Loading scene...</Spinner>
  </PageContent>
}