import { useContext } from 'react';
import TranslationsSource from '../Translations.json'
import PageContext from '../PageContext';

export type Translation = keyof typeof TranslationsSource;

export default function Translate<T extends Translation>(text: T) {
  const user = useContext(PageContext);

  if (!user || !user.locale)
    return text;

  const textTranslations = TranslationsSource[text]
  if (!textTranslations) {
    throw new Error('No translation found for: `' + text + '`')
  }
  return textTranslations[user.locale];
}
