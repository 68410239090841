import JoinSessionRequest, { VerifySessionStateRequest } from '../src/net/SessionApiClient';
import type ParticipationInfo from '../src/types/ParticipationInfo';
import type { ActorType } from '../src/types/ParticipationInfo';
import type Profile from './types/Profile';
import type UserAuth from './types/UserAuth';

export default async function Participate(user: UserAuth & Profile, abortSignal: AbortSignal, actorType: ActorType) {
  // get the session id from url params and clean it up after
  const params = new URLSearchParams(window.location.search);
  const sessionId = params.get('session') ?? '';

  const invalidParticipation: ParticipationInfo = {
    userId: user.sub,
    sessionId: sessionId,
    cullerHost: import.meta.env.VIRTALIS_CULLING_ACTOR_HOST,
    treeHost: import.meta.env.VIRTALIS_TREE_ACTOR_HOST,
  };
  if (sessionId.length <= 0)
    return invalidParticipation;

  try {
    await VerifySessionStateRequest(user, abortSignal, sessionId);
    const info = await JoinSessionRequest(user, abortSignal, sessionId, actorType);
    return info;
  } catch (error) {
    console.warn("Unverified session state:", error);
    return invalidParticipation;
  }
}
