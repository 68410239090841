import { useContext, useEffect, useState } from 'react';
import ControlContext from './ControlContext';
import ConnectionState from '../../src/types/ConnectionState';
import cssc from './ClientControls.module.scss';

export default function SystemStatus() {
  const {
    setConnectionStatusCallback,
    setMeshesPendingCallback,
    setDrawablesPendingCallback,
  } = useContext(ControlContext);

  const [connectionStatus, setConnectionStatus] = useState(ConnectionState.Unintialised);
  const [meshesPending, setMeshesPending] = useState(0);
  const [drawablesPending, setDrawablesPending] = useState(0);

  useEffect(() => {
    setConnectionStatusCallback(() => setConnectionStatus);
    setMeshesPendingCallback(() => setMeshesPending);
    setDrawablesPendingCallback(() => setDrawablesPending);

    return () => {
      setConnectionStatusCallback(null);
      setMeshesPendingCallback(null);
      setDrawablesPendingCallback(null);
    };
  }, [setConnectionStatusCallback, setDrawablesPendingCallback, setMeshesPendingCallback]);

  const connectionStatusColour = (() => {
    switch (connectionStatus) {
      case ConnectionState.Connected:
        return 'lime';
      case ConnectionState.Connecting:
      case ConnectionState.Unintialised:
        return 'orange';
      case ConnectionState.Closed:
      case ConnectionState.Failed:
        return 'red';
      default:
        return 'blueviolet';
    }
  })();


  return <>
    <div style={{ gridColumn: 1 }} className={cssc['clientControls']} >
      <span style={{ color: connectionStatusColour }}>{'\uf20e'}</span>
      <span style={{ opacity: meshesPending === 0 ? 0.0 : 1.0 }}>{'\ue2c0'}</span>
      <span style={{ opacity: drawablesPending === 0 ? 0.0 : 1.0 }}>{'\uf720'}</span>
    </div>
  </>;
}
