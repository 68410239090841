import Button from "../../src/components/Button";

export default function About() {
  const commitDate = new Date(import.meta.env.VIRTALIS_GIT_COMMIT_DATE);

  return <>
    <span>Version: </span>
    <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '1em', alignItems: 'baseline' }}>
      <span>{import.meta.env.VIRTALIS_GIT_BRANCH_NAME}@{import.meta.env.VIRTALIS_GIT_COMMIT_HASH}</span>
      {'\u{00A0}'}
      <small>{commitDate.toLocaleString([], { dateStyle:'short', timeStyle:'short' })}</small>
    </div>
    <p>{import.meta.env.VIRTALIS_GIT_LAST_COMMIT_MESSAGE}</p>
    <Button
      href='/thirdparty.json'
      target='_blank'
      rel='noreferrer noopener'
      style={{ margin: 'auto' }}
    >
      Third party license information
    </Button>
  </>;
}