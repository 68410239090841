import "@fontsource-variable/material-symbols-outlined";
import './roboto.css';
import './roboto-italic.css'
import './index.scss'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import PageShell from './components/PageShell.tsx'
import ArtifactPage from './index/ArtifactPage.tsx';
import LoadingPage from './loading/LoadingPage.tsx';
import ErrorPage from './ErrorPage.tsx';
import SessionsPage from './sessions/SessionsPage.tsx';
import JobsPage from './jobs/JobsPage.tsx';
import ProjectsPage from './projects/ProjectsPage.tsx';
import LibraryPage from './library/LibraryPage.tsx';
import EditPage from './edit/EditPage.tsx';
import CreatePage from './create/CreatePage.tsx';
import ImportPage from './assets/import/ImportPage.tsx';
import { Page } from './client/index.page.tsx'

const container = document.getElementById('root');
if (!container)
  throw new Error("Failed to find react root element");

const root = ReactDOM.createRoot(container);

root.render(
  <StrictMode>
    <PageShell>
      {(() => {
        switch (location.pathname) {
          case '/': case '': return <ArtifactPage />;
          case '/legacy': return <ArtifactPage legacyMode />;
          case '/loading': return <LoadingPage />;
          case '/sessions': return <SessionsPage />;
          case '/jobs': return <JobsPage />;
          case '/projects': return <ProjectsPage />;
          case '/edit': return <EditPage />;
          case '/library': return <LibraryPage />;
          case '/create': return <CreatePage />;
          case '/import': return <ImportPage />;
          case '/client': return <Page />;
          default: return <ErrorPage is404 />;
        }
      })()}
    </PageShell>
  </StrictMode>,
)
