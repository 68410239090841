import { type MouseEventHandler, useCallback, useContext, useRef } from "react";
import PageContext from "./../PageContext";
import About from "./About";
import Dialog, { type DialogHandle } from "../../src/components/Dialog";
import css from './UserProfile.module.scss';
import { genColourHSL } from '../../src/utils/genColour';

export default function UserProfile() {
  const user = useContext(PageContext);
  const profileDialogRef = useRef<DialogHandle>(null);
  const aboutDialogRef = useRef<DialogHandle>(null);

  const name = user?.name ?? "";
  const [hue, sat, lum] = genColourHSL(user?.sub ?? '');
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  const matches = [...name.matchAll(rgx)] || [];
  const initials = (
    (matches.shift()?.[1] ?? '') + (matches.pop()?.[1] ?? '')
  ).toUpperCase();

  const navigateToProfilePage = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    if (!user?.profileUrl)
      throw new Error("Attempted to navigate to profile page, however url is not defined");
    location.href = user.profileUrl();
  }, [user]);

  return user && <>
    <button
      className={css['userProfile']}
      style={{
        backgroundColor: `hsl(${hue}deg ${sat}% ${lum}%)`,
      }}
      onClick={() => {
        if (profileDialogRef.current)
          profileDialogRef.current.show();
      }}
    >{initials ?? '??'}</button>

    <Dialog
      ref={aboutDialogRef}
      modal
      title='About Virtalis Reach'
    >
      <About />
    </Dialog>

    <Dialog
      ref={profileDialogRef}
      automaticallyCloseOnFocusLoss
      title={user.name}
      className={css['profile-menu']}
      style={{
        top: '4em',
        width: 200,
      }}
    >
      <form method='dialog'>
        <button onClick={navigateToProfilePage}>Profile</button>
        <button onClick={() => {
          if (aboutDialogRef.current)
            aboutDialogRef.current.show();
        }}>About Reach</button>
        <button onClick={user.logout}>Log out</button>
      </form>
    </Dialog>
  </>;
}
