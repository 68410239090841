import type UserAuth from "../types/UserAuth";

export default async function AcquireUploadFolder(user: UserAuth, signal?: AbortSignal) {

  const r = await fetch(`/hub/api/Folder/ListFolders`, {
    headers: { 'Authorization': `Bearer ${user.access_token}` },
    signal,
  });

  if (!r.ok)
    throw new Error(r.statusText);

  // Make a few assumptions here and expect correct data format is returned
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const result: {
    containedFiles: string[];
    createdDate: string;
    folderId: string;
    submittedForImport: boolean;
    userId: string;
  }[] = await r.json();

  if (!Array.isArray(result) || !result)
    throw new Error("Unknown response from folder API");

  return result[0];
}
