import HubAside from '../components/HubAside';
import PageContent from '../../src/components/PageContent';
import Button from '../../src/components/Button';
import PageHeader from '../../src/components/PageHeader';
import css from './EditPage.module.scss';
import { Children, useCallback, useContext, useEffect, useRef, useState, type MouseEventHandler } from 'react';
import General from './General';
import Visualisation from './Visualisation';
import Access from './Access';
import Publish from './Publish';
import Advanced from './Advanced';
import PageContext from '../PageContext';
import Spinner from '../../src/components/Spinner';
import ProjectContext, { isPublishAction, type IProjectState, type ProjectContextState, type ProjectMetaData } from './ProjectContext';
import AutoFetch from '../utils/AutoFetch';
import Alert from '../../src/components/Alert';
import ToastContext from '../../src/components/ToastContext';

enum Pages {
  General,
  Visualisation,
  Access,
  Publications,
  Advanced,
}
const pageIds = Object.values(Pages).filter(function isNumber(v): v is number { return typeof v === 'number'; });

function GetPageFromLocation() {
  const hash = location.hash.replace('#', '');
  if (pageIds.find((pageId => Pages[pageId] === hash)))
    return hash as keyof typeof Pages;
  return Pages[0] as keyof typeof Pages;
}

export default function EditPage() {
  const user = useContext(PageContext);
  const toaster = useContext(ToastContext);
  const [currentHash, setHash] = useState(GetPageFromLocation());
  const titleRef = useRef<HTMLSpanElement>(null);
  const id = new URLSearchParams(location.search).get('id') ?? '';
  const [project, setProject] = useState<ProjectContextState>();

  const [changeCount, setChangeCount] = useState(0);
  const [publishing, setPublishInProgress] = useState(false);
  const [saving, setSaveInProgress] = useState(false);

  onpopstate = () => {
    setHash(GetPageFromLocation());
  };

  useEffect(() => {
    if (!user)
      return;

    let aborted = false;
    const cancel = AutoFetch(
      `/hub/api/projects/${id}`,
      () => ({ headers: { 'Authorization': `Bearer ${user.access_token}` } }),
      data => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { projectMetadata, ...state }: Omit<IProjectState, 'name'> & { projectMetadata: ProjectMetaData; } = data;
        state.groups = state.groups.filter(v => v !== user?.sub);
        for (const action of state.actions) {
          if (!isPublishAction(action))
            continue;
          if (action.audience)
            (action.audience as unknown) = action.audience.filter(v => v !== user?.sub);
        }

        const projectState = {
          name: projectMetadata.name,
          ...state,
          setProjectState(state) {
            Object.assign(this, state);
            if (titleRef.current)
              titleRef.current.textContent = this.name;
            setChangeCount(v => v + 1);
          }
        } satisfies typeof project;

        setChangeCount(0);
        setPublishInProgress(false);
        setSaveInProgress(false);
        setProject(v => aborted ? v : projectState);
      },
      () => { },
      { retryCount: 3, refreshDelay: 0 }
    );

    return () => { cancel(); aborted = true; };
  }, [id, user]);

  const saveProject = useCallback<MouseEventHandler>((e) => {
    if (!project || !user)
      return;
    if (!(e.target instanceof HTMLButtonElement))
      return;
    setSaveInProgress(true);
    const currentChangeCount = changeCount;

    fetch(`/hub/api/projects/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${user.access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...project, uuid: id })
    }).then(res => {
      if (!res.ok)
        throw new Error(res.statusText);
      toaster?.showToast("Saved project changes", { type: "success" });
      setChangeCount(v => v - currentChangeCount);
    }).catch(e => {
      toaster?.showToast("Failed to sumbit project changes", { type: "error" });
      console.warn(e);
    }).finally(() => {
      setSaveInProgress(false);
    });

  }, [changeCount, id, project, toaster, user]);

  const publishProject = useCallback<MouseEventHandler>(() => {
    if (!user || !project)
      return;

    setPublishInProgress(true);

    fetch(`/hub/api/projects/${id}/build`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${user.access_token}`,
        "Content-Type": "application/json",
      },
    }).then(res => {
      if (!res.ok)
        throw new Error(res.statusText);
      toaster?.showToast("Project publishing queued", { type: "success" });
    }).catch(e => {
      toaster?.showToast("Failed to queue project publishing", { type: "warning" });
      console.warn(e);
    }).finally(() => {
      setPublishInProgress(false);
    });
  }, [id, project, toaster, user]);

  return <PageContent>
    <HubAside current='projects' />

    {!id
      ? <div><Alert severity="error">ID not supplied on URL</Alert></div>
      : !project ? <Spinner style={{ placeSelf: 'center' }}>Loading project info</Spinner>
        : <ProjectContext.Provider value={project}>
          <PageHeader
            title={
              <>
                <a href='projects'>Projects</a>
                {` / `}
                <span ref={titleRef} >{project.name}</span>
              </>
            }
          >
            <div style={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: '0.25em',
            }} >
              <Button disabled={saving || publishing || changeCount <= 0} primary onClick={saveProject} >Save changes</Button>
              <Button disabled={saving || publishing} primary onClick={publishProject} >Publish now</Button>
            </div>
            <nav className={css['nav']}>
              {Children.map(pageIds, pageId => {
                const page = Pages[pageId];
                return <a
                  className={currentHash === page ? css['active'] : undefined}
                  href={`#${page}`}
                >{page}</a>;
              })}
            </nav>

          </PageHeader>

          <div className={css['contents']} style={{ display: 'flex', flexDirection: 'column' }} >
            {
              function () {
                switch (currentHash) {
                  case 'General': return <General />;
                  case 'Visualisation': return <Visualisation />;
                  case 'Access': return <Access />;
                  case 'Publications': return <Publish />;
                  case 'Advanced': return <Advanced />;
                  default: return <></>;
                }
              }()
            }
          </div>
        </ProjectContext.Provider>}
  </PageContent>;
}
