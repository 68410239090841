import type {DeviceType} from '../../src/types/DeviceType';
import type { XRMode } from '../../src/types/XRMode';
import css from './XRButton.module.scss';

export default function XRButton(props: { setDevice: (device: DeviceType) => void, mode: XRMode; }) {
  const { setDevice, mode } = props;

  return (
    <button
      className={css["xrButton"]}
      onClick={() => {
        setDevice(mode);
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
        <path d="M80-691v-129q0-24.75 17.625-42.375T140-880h129v60H140v129H80ZM269-80H140q-24.75 0-42.375-17.625T80-140v-129h60v129h129v60Zm422 0v-60h129v-129h60v129q0 24.75-17.625 42.375T820-80H691Zm129-611v-129H691v-60h129q24.75 0 42.375 17.625T880-820v129h-60Z" />
      </svg>
      <span>{mode.toUpperCase()}</span>
    </button>
  );
}