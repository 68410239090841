import { useCallback, useContext, useRef, useState, type FormEventHandler } from "react";
import Button from "../../src/components/Button";
import PageContent from "../../src/components/PageContent";
import PageHeader from "../../src/components/PageHeader";
import Stepper from "../../src/components/Stepper";
import HubAside from "../components/HubAside";
import ProjectContext from "../edit/ProjectContext";
import General from "../edit/General";
import Visualisation from "../edit/Visualisation";
import Access from "../edit/Access";
import Publish from "../edit/Publish";
import PageContext from "../PageContext";
import ToastContext from "../../src/components/ToastContext";
import Spinner from "../../src/components/Spinner";

function CreatePageContents() {
  const [page, setPage] = useState(0);
  const currentPage = useRef<HTMLFormElement>(null);
  const project = useContext(ProjectContext);
  const [inProgress, setInProgress] = useState(false);
  const [completedProjectId, setProjectId] = useState<string>();
  const user = useContext(PageContext);
  const toaster = useContext(ToastContext);

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>((e) => {
    e.preventDefault();
    if (!user)
      throw new Error("Attempted submit but user is not valid");
    const shouldAutoPublish = e.nativeEvent instanceof SubmitEvent &&
      e.nativeEvent.submitter instanceof HTMLButtonElement &&
      e.nativeEvent.submitter.value === 'publish';

    setInProgress(true);
    fetch('/hub/api/projects', {
      method: 'post',
      headers: {
        'Authorization': `Bearer ${user.access_token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...project })
    }).then(async (res) => {
      if (!res.ok)
        throw new Error(res.statusText);

      toaster?.showToast("Project created", { type: "success" });
      const id = await res.text();

      if (shouldAutoPublish)
        await fetch(`/hub/api/projects/${id}/build`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
        }).then(res => {
          if (!res.ok)
            throw new Error(res.statusText);
          toaster?.showToast("Project publishing queued, check jobs page for status", { type: "success" });
        }).catch(e => {
          toaster?.showToast("Failed to queue project publishing", { type: "warning" });
          console.warn(e);
        });

      setProjectId(id);
      setTimeout(() => {
        location.pathname = `/projects`;
      }, 2000);
    }).catch((e) => {
      toaster?.showToast("Failed to create project", { type: "error" });
      console.warn(e);
    }).finally(() => {
      setInProgress(false);
    });

    return false;
  }, [project, toaster, user]);

  const nextPage = useCallback(() => {
    const current = currentPage.current;
    if (current && !current.checkValidity()) {
      current.reportValidity();
      return false;
    }
    setPage(v => Math.min(v + 1, 3));
    return false;
  }, []);

  return <>
    {
      completedProjectId ?
        <Spinner style={{ placeSelf: 'center' }}>Navigating to projects page</Spinner> :
        <>
          <PageHeader title="Create Project" />

          <aside>
            <Stepper
              currentStep={page}
              autoHorizontal
            >
              <>
                <small>Project</small>
                Settings
              </>
              <>
                <small>Configure</small>
                Visualisation
              </>
              <>
                <small>Share</small>
                Settings
              </>
              <>
                <small>Ready to</small>
                Publish
              </>
            </Stepper>
          </aside>

          <form id='vtProjCreate' ref={currentPage} style={{ margin: 12 }} onSubmit={onSubmit}>
            {
              (() => {
                switch (page) {
                  case 0: return <General />;
                  case 1: return <Visualisation />;
                  case 2: return <Access />;
                  case 3: return <Publish />;
                  default: return `${page}`;
                }
              })()
            }
          </form>

          <footer style={{ display: 'flex', padding: 8, gap: 8 }} >
            <Button style={{ marginRight: 'auto' }} onClick={() => { location.pathname = `/projects`; }}>Cancel</Button>
            {!!page && <Button transparent disabled={inProgress} onClick={() => { setPage(v => v - 1); }}>Previous</Button>}
            {page === 3 ?
              <>
                <Button primary disabled={inProgress} form='vtProjCreate' type='submit'>Save</Button>
                <Button primary disabled={inProgress} form='vtProjCreate' type='submit' value='publish'>Save and publish</Button>
              </> :
              <Button primary onClick={nextPage}>Next</Button>
            }
          </footer>
        </>
    }
  </>;
}

export default function CreatePage() {

  return <ProjectContext.Provider value={{
    name: '',
    actions: [],
    groups: [],
    allowScripts: false,
    hourToPublish: 0,
    trigger: 'manual',
    setProjectState(state) {
      Object.assign(this, state);
    }
  }} >
    <PageContent>
      <HubAside current='projects' />
      <CreatePageContents />
    </PageContent>
  </ProjectContext.Provider>;
}
