export default class PacketReader {
  private view: DataView;
  private byteOffset: number;

  constructor(src: ArrayBuffer, accountForHeader = true) {
    this.view = new DataView(src);
    if (accountForHeader)
      this.byteOffset = 2; // Automatically account for the header
    else
      this.byteOffset = 0;
  }

  get type(): number {
    return this.view.getUint16(0, true);
  }

  get atEnd() {
    return this.byteOffset >= this.view.byteLength;
  }

  getInt8() {
    const result = this.view.getInt8(this.byteOffset);
    this.byteOffset += 1;
    return result;
  }
  getInt16() {
    const result = this.view.getInt16(this.byteOffset, true);
    this.byteOffset += 2;
    return result;
  }
  getInt32() {
    const result = this.view.getInt32(this.byteOffset, true);
    this.byteOffset += 4;
    return result;
  }
  getInt64() {
    const result = this.view.getBigInt64(this.byteOffset, true);
    this.byteOffset += 8;
    return result;
  }

  getUint8() {
    const result = this.view.getUint8(this.byteOffset);
    this.byteOffset += 1;
    return result;
  }
  getUint16() {
    const result = this.view.getUint16(this.byteOffset, true);
    this.byteOffset += 2;
    return result;
  }
  getUint32() {
    const result = this.view.getUint32(this.byteOffset, true);
    this.byteOffset += 4;
    return result;
  }
  getUint64() {
    const result = this.view.getBigUint64(this.byteOffset, true);
    this.byteOffset += 8;
    return result;
  }

  getFloat32() {
    const result = this.view.getFloat32(this.byteOffset, true);
    this.byteOffset += 4;
    return result;
  }
  getFloat64() {
    const result = this.view.getFloat64(this.byteOffset, true);
    this.byteOffset += 8;
    return result;
  }

  getBool() {
    const result = this.view.getUint8(this.byteOffset) > 0;
    this.byteOffset += 1;
    return result;
  }

  getString() {
    let buffer = new Uint8Array(this.view.buffer, this.byteOffset);
    let count = buffer.indexOf(0);
    if (count === -1)
      throw new Error('Non null terminated string');

    buffer = new Uint8Array(this.view.buffer, this.byteOffset, count);
    ++count; // account for null termination
    const td = new TextDecoder();
    const result = td.decode(buffer);

    this.byteOffset += count;
    return result;
  }

  getInt8Array(size: number) {
    const result = new Int8Array(this.view.buffer, this.byteOffset, size);
    this.byteOffset += size;
    return result;
  }

  getInt16Array(size: number) {
    const result = new Int16Array(this.view.buffer, this.byteOffset, size);
    this.byteOffset += (size * 2);
    return result;
  }

  getInt32Array(size: number) {
    const result = new Int32Array(this.view.buffer, this.byteOffset, size);
    this.byteOffset += (size * 4);
    return result;
  }

  getUint8Array(size: number) {
    const result = new Uint8Array(this.view.buffer, this.byteOffset, size);
    this.byteOffset += size;
    return result;
  }

  getUint16Array(size: number) {
    const result = new Uint16Array(this.view.buffer, this.byteOffset, size);
    this.byteOffset += (size * 2);
    return result;
  }

  getUint32Array(size: number) {
    const result = new Uint32Array(this.view.buffer, this.byteOffset, size);
    this.byteOffset += (size * 4);
    return result;
  }
}