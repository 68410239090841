import { useCallback, type PropsWithChildren, type FocusEventHandler, useRef, forwardRef, useImperativeHandle, type MouseEventHandler, type DialogHTMLAttributes } from 'react';
import css from './Dialog.module.scss';

export interface DialogHandle extends Pick<HTMLDialogElement, 'close'> {
  // will display the dialog in either a modal or modelessly
  // (allowing interaction with content outside of the dialog)
  // depending on the if the modal prop has been set
  show: VoidFunction;
}

export interface DialogProps extends DialogHTMLAttributes<unknown> {
  automaticallyCloseOnFocusLoss?: boolean;
  modal?: boolean;
  title?: string;
}

const Dialog = forwardRef<DialogHandle, PropsWithChildren<DialogProps>>(function Dialog({
  children,
  automaticallyCloseOnFocusLoss,
  modal,
  title,
  ...props
}, ref) {

  if (modal && automaticallyCloseOnFocusLoss)
    throw new Error("Cannot use modal and auto close props at the same time");

  const dialogRef = useRef<HTMLDialogElement>(null);
  const closeOnFocusLoss = useCallback<FocusEventHandler<HTMLDialogElement>>(function (ev) {
    if (dialogRef.current && !dialogRef.current.contains(ev.relatedTarget))
      dialogRef.current.close();
  }, [dialogRef]);

  useImperativeHandle(ref, () => {
    const ref = dialogRef.current;
    if (!ref)
      throw new Error("Dialog ref is undefined");

    return {
      show() {
        if (modal)
          ref.showModal();
        else
          ref.show();
      },
      close(returnValue?: string) {
        ref.close(returnValue);
      },
    };
  }, [modal]);

  const closeDialog = useCallback<MouseEventHandler<HTMLButtonElement>>(function () {
    if (dialogRef.current)
      dialogRef.current.close();
  }, []);

  return <dialog
    {...props}
    className={css["dialog"].concat(props.className ? ` ${props.className}` : '')}
    ref={dialogRef}
    onBlur={automaticallyCloseOnFocusLoss ? closeOnFocusLoss : undefined}
  >
    {(!!title || modal) && <header>
      <span>{title} &#160;</span>
      {modal && <button onClick={closeDialog} type='button'>&times;</button>}
    </header>}
    {children}
  </dialog>;
});

export default Dialog;