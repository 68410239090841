import { useCallback, useRef, useState, type CSSProperties, type ComponentPropsWithoutRef, type DragEventHandler, type PropsWithChildren } from "react";
import css from './DropZone.module.scss';

function isDirectoryEntry(v: FileSystemEntry): v is FileSystemDirectoryEntry { return v.isDirectory; }
function isFileEntry(v: FileSystemEntry): v is FileSystemFileEntry { return v.isFile; }

export default function DropZone({ children, style, className, ...props }: PropsWithChildren<ComponentPropsWithoutRef<'input'>>) {
  const [outline, setOutline] = useState<CSSProperties['outline']>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  // This is where the drop is handled.
  const onDrop = useCallback<DragEventHandler>((e) => {
    // Prevent navigation.
    e.preventDefault();

    // Unhighlight the drop zone.
    setOutline(undefined);

    // �by including only files (where file misleadingly means actual file _or_
    // directory)�
    const items = [...e.dataTransfer.items].filter((item) => item.kind === 'file');

    function recursiveAddEntry(handle: FileSystemEntry, path = '') {
      if (isDirectoryEntry(handle)) {
        path += handle.name + "/";
        const dirReader = handle.createReader();
        const readEntries = () => {
          dirReader.readEntries(function (entries) {
            for (const child of entries)
              recursiveAddEntry(child, path);
            // continue reading there might be more entries
            readEntries();
          });
        };
        // Get folder contents
        readEntries();
      }
      else if (isFileEntry(handle)) {
        // Get file
        handle.file(function (file) {

          const inputRef = fileInputRef.current;
          if (!inputRef)
            throw new Error("Input handler not defined");

          // to programatically update input element with files
          // https://dev.to/code_rabbi/programmatically-setting-file-inputs-in-javascript-2p7i
          const dataTransfer = new DataTransfer();
          // keep existing files in input element
          if (inputRef.files)
            for (const file of inputRef.files)
              dataTransfer.items.add(file);
          // to programatically specify relative directory on a file
          // https://web.dev/patterns/files/open-a-directory/
          dataTransfer.items.add(Object.defineProperty(file, "webkitRelativePath", {
            configurable: true,
            enumerable: true,
            get: () => path.replace(/\/$/, ''),// remove trailing slash
          }));
          inputRef.files = dataTransfer.files;
          // ensure events are dispatched as we're programtically adding data
          inputRef.dispatchEvent(new Event('change', { bubbles: true }));
          inputRef.dispatchEvent(new Event('input', { bubbles: true }));
        });
      }
      else
        throw new Error("Unexpected FileSystemEntry format detected");
    }

    // Loop over the dropped files/directories.
    for (const item of items) {
      recursiveAddEntry(item.webkitGetAsEntry() as FileSystemEntry);
    }
  }, []);

  return <div
    className={css['dropZone'] + (className ? ` ${className}`:'')}
    style={{ ...style, outline }}
    onDrop={onDrop}
    onDragOver={function (e) {
      // Prevent navigation.
      e.preventDefault();
    }}

    onDragEnter={() => {
      // Visually highlight the drop zone.
      setOutline('solid red 1px');
    }}

    onDragLeave={() => {
      // Visually unhighlight the drop zone.
      setOutline(undefined);
    }}

    onClick={() => {
      if (!fileInputRef.current)
        return;
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }}
  >
    <input
      ref={fileInputRef}
      style={{ display: 'none' }}
      type="file"
      multiple
      {...props}
    />
    {children}
  </div>;
}