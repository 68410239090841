import { useRef } from 'react';
import css from './TabGroup.module.scss';
interface TabGroupProps {
  labels: string[];
  onTabChange?: (index: number) => void; // a function that gets called when this item is clicked. Argument is the index of the selected element
}
export default function TabGroup({ labels, onTabChange }: TabGroupProps) {
  const selectedItemRef = useRef<HTMLLIElement | null>(null);

  const onTabClick = (index: number) => (ev: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (ev.currentTarget === selectedItemRef.current)
      return;

    if (onTabChange)
      onTabChange(index);

    if (selectedItemRef.current) {
      selectedItemRef.current.className = ""; // remove the old class name
    }

    ev.currentTarget.className = css["selected"];
    selectedItemRef.current = ev.currentTarget;
  };

  return (
    <ul className={css["tab-container"]}>
      {labels.map((item, index) => (
        <li
          ref={index === 0 ? selectedItemRef : null } // if this is the first item assign the ref to it
          className={index === 0 ? css["selected"] : "" } // default the first item to be the selected item
          onClick={onTabClick(index)}
          key={index}>
          {item}
        </li>
      ))}
    </ul>
  );
}