import css from './EnabledIcon.module.scss';
import SceneListIcon from 'tree-icons/scenelist.svg';
import AssemblyIcon from 'tree-icons/assembly.svg';
import VisualIcon from 'tree-icons/visual.svg';
import ViewpointListIcon from 'tree-icons/viewpointlist.svg';
import ViewpointIcon from 'tree-icons/viewpoint.svg';
import MissingIcon from 'tree-icons/default.svg';

interface EnabledIconProps {
  enabled: boolean;
  type: string;
  updateFunc: VoidFunction
}

export default function EnabledIcon({ enabled, type, updateFunc }: EnabledIconProps) {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    updateFunc();
  };

  const icons: Record<string, string> = {
    scenelist: SceneListIcon,
    viewpointlist: ViewpointListIcon,
    viewpoint: ViewpointIcon,
    assembly: AssemblyIcon,
    visual: VisualIcon,
    default: MissingIcon,
  };

  return (
    <div>
      <div className={enabled ? '' : css["node-icon-disabled"]}>
        <img src={icons[type.toLowerCase()] || icons.default} className={css["node-visibility"]} onClick={handleClick} />
      </div>
    </div>
  );
}