import type { PropsWithChildren } from "react";

export default function PaginationWrapper({ children }: PropsWithChildren) {
  return <div style={{
    width: 'max-content',
    maxWidth: '100%',
    paddingTop: 1,
    paddingBottom: '0.5rem',
    margin: '0.5em auto 0px',
  }} >
    {children}
  </div>;
}