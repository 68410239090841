import { FiRepeat } from "react-icons/fi";
import { BiCube, BiLayer } from "react-icons/bi";
import css from './HubAside.module.scss';

export default function HubAside({ current }: { current: 'projects' | 'assets' | 'jobs'; }) {
  return <nav className={css['hub-nav']} >
    <a className={current === 'assets' ? css['active'] : undefined} href="/library">
      <BiLayer size={28} />
      Assets
    </a>
    <a className={current === 'projects' ? css['active'] : undefined} href="/projects">
      <BiCube size={28} />
      Projects
    </a>
    <a className={current === 'jobs' ? css['active'] : undefined} href="/jobs">
      <FiRepeat size={28} />
      Jobs
    </a>
  </nav>;
}
