import { type HTMLAttributes } from "react";
import css from './Alert.module.scss';

interface AlertProps {
  severity?: 'success' | 'info' | 'warning' | 'error';
}

export default function Alert(props: AlertProps & HTMLAttributes<HTMLDivElement>) {
  const { severity, className, children, ...rest } = props;
  const btnClass = `${className ?? ''} ${css['reach-alert']} ${(severity && severity !== 'info') ? css[severity] : ''}`;
  return <div className={btnClass} {...rest}>{children}</div>;
}
