import { useContext, useEffect, useRef, useState } from 'react';
import Button from '../../src/components/Button';
import Dialog, { type DialogHandle } from '../../src/components/Dialog';
import css from './Visualisation.module.scss';
import ProjectContext, { type AddDataAction, isAddDataAction, isPublishAction, isTemplateAction, type TemplateAction } from './ProjectContext';
import Input from '../../src/components/Input';
import Select from '../../src/components/Select';
import AssetOrderBy from '../types/AssetOrderBy';
import AutoFetch from '../utils/AutoFetch';
import PageContext from '../PageContext';
import type IAssets from '../types/IAssets';
import { DateTime } from 'luxon';
import { Editor } from '@monaco-editor/react';
import RulesSchema from './RulesSchema.json';
import RulesSchemaUrl from './RulesSchema.json?url';
import UsingDarkTheme from '../utils/UsingDarkTheme';
import InputPlaceholder from '../../src/components/InputPlaceholder';

export default function Visualisation() {
  const editDialogRef = useRef<DialogHandle>(null);
  const assetPickerDialogRef = useRef<DialogHandle>(null);
  const project = useContext(ProjectContext);
  const [usedAssets, setUsedAssets] = useState<AddDataAction[]>();
  const [templateScript, setTemplateString] = useState<string>();
  const [searchString, setSearchString] = useState<string>();
  const [sortOrder, setOrder] = useState(AssetOrderBy.NameASC);
  const user = useContext(PageContext);
  const [assets, setAssets] = useState<IAssets>();
  const darkTheme = UsingDarkTheme();

  const usedAssetsWithFallback = usedAssets ?? project.actions.filter(isAddDataAction);

  useEffect(() => {
    let abandoned = false;
    let initialResultsAcquired = false;

    const stopFetch = AutoFetch(
      `hub/api/models?${new URLSearchParams({
        SortBy: 'name',
        SortOrder: sortOrder,
        ...(searchString ? { ModelNameFilter: searchString } : {})
      }).toString()}`,
      () => ({ headers: { 'Authorization': `Bearer ${user?.access_token}` } }),
      (data) => {
        initialResultsAcquired = true;
        setAssets(v => abandoned ? v : data as IAssets);
      },
      () => {
        if (!abandoned)
          console.warn(`Unable to ${initialResultsAcquired ? 'refresh' : 'retrieve'} the projects list.`);
      },
      {
        refreshDelay: 0,
        retryCount: 3
      });

    return () => {
      abandoned = true;
      stopFetch();
    };
  }, [searchString, sortOrder, user]);



  useEffect(() => {
    if (usedAssets === undefined)
      return;

    project.setProjectState({
      ...project,
      actions: [
        ...usedAssets.filter((v) => !project.actions.includes(v)),
        ...project.actions.filter((v) => !isAddDataAction(v) || usedAssets.includes(v))]
    });
  }, [usedAssets, project]);

  useEffect(() => {
    if (templateScript === undefined)
      return;
    let minifiedScript = templateScript;
    try {
      minifiedScript = JSON.stringify(JSON.parse(minifiedScript));
    } catch { return; }

    let actions = [...project.actions];
    if (!minifiedScript || /^[\s[\]{}]+$/.test(minifiedScript))
      actions = actions.filter(v => !isTemplateAction(v));
    else {
      const existingTemplate = actions.find(isTemplateAction);
      if (existingTemplate) {
        actions = actions.map(v => v === existingTemplate ? {
          type: 'APPLY_TEMPLATE',
          template_id: v.template_id,
          templateString: minifiedScript,
        } satisfies TemplateAction : v);
      } else {
        let insertPosition = actions.findIndex(isPublishAction);
        if (insertPosition < 0) {
          insertPosition = Math.min(0, actions.length);
          console.warn("There's no publishing stage defined, adding template as final action");
        }

        actions.splice(insertPosition, 0, {
          type: 'APPLY_TEMPLATE',
          template_id: '',
          templateString: minifiedScript,
        } satisfies TemplateAction);
      }
    }

    project.setProjectState({
      ...project,
      actions
    });
  }, [usedAssets, project, templateScript]);

  return <div className={css['controls']}>
    <div>
      <strong>Generic rules</strong>
      These rules will be applied to the entire visualisation
      <div>
        <strong>Entire visualisation</strong>
        <div>
          Custom rules
          <Button onClick={() => { editDialogRef.current?.show(); }} type='button'>Edit</Button>
          <Dialog
            ref={editDialogRef}
            title='Edit rules'
            modal
            style={{
              width: 'calc(100vw - 80px)',
              height: 'calc(100vh - 80px)'
            }}
          >
            <Editor
              language='json'
              theme={darkTheme ? 'vs-dark' : 'light'}
              onMount={(_, m) => {
                m.languages.json.jsonDefaults.setDiagnosticsOptions({
                  ...m.languages.json.jsonDefaults.diagnosticsOptions,
                  allowComments: false,
                  validate: true,
                  schemaValidation: 'warning',
                  schemas: [{
                    uri: RulesSchemaUrl,
                    fileMatch: ['*.rules.json'],
                    schema: RulesSchema
                  }]
                });
              }}
              path='rules.json'
              options={{ minimap: { enabled: false } }}
              value={templateScript ?? JSON.stringify(
                JSON.parse(project.actions.find(isTemplateAction)?.templateString || '[]'),
                undefined,
                2
              )}
              onChange={(v) => { setTemplateString(v); }}
            />
          </Dialog>
        </div>
      </div>
    </div>
    <div style={{ display: 'grid' }}>
      <div>
        <strong>Add assets</strong>
        <Button type='button' onClick={() => { assetPickerDialogRef.current?.show(); }}>Add asset</Button>
        <Dialog
          ref={assetPickerDialogRef}
          title='Assets'
          modal
          style={{ width: 'calc(100vw - 80px)', height: 'calc(100vh - 80px)' }}
        >
          <div style={{
            display: 'flex',
            textAlign: 'right',
            flexWrap: 'wrap',
            gap: '0.5em 1em',
          }}>
            <Input style={{ flexGrow: 1 }} type='search' placeholder='Filter by name' value={searchString} onChange={e => setSearchString(e.target.value)} />
            <label style={{ flexGrow: 1, maxWidth: 'max-content', marginLeft: 'auto' }}>
              {'\u{00A0}'}
              Order by
              {'\u{00A0}'}
              <Select value={sortOrder} onChange={e => setOrder(e.target.value as AssetOrderBy)}>
                <option value={AssetOrderBy.NameASC}>{'Name \u{2191}'}</option>
                <option value={AssetOrderBy.NameDESC}>{'Name \u{2193}'}</option>
              </Select>
            </label>
          </div>
          <fieldset
            style={{
              display: 'grid',
              borderStyle: 'none',
              gap: 8,
              paddingBlockStart: '1em',
            }}
          >
            
            {assets && assets.vrModels.map((asset) =>
              <label
                key={asset.id}
                className={css['accessLabel']}
              >
                <Input
                  type="checkbox"
                  checked={!!(usedAssets ?? project.actions.filter(isAddDataAction)).find(v => v.vrmodel === asset.id)}
                  onChange={(e) => {
                    setUsedAssets(v => {
                      if (!v)
                        v = project.actions.filter(isAddDataAction);

                      return e.target.checked ?
                        [
                          { vrmodel: asset.id, shortName: asset.name, type: 'ADD_DATA' },
                          ...v
                        ] :
                        v.filter(x => x.vrmodel !== asset.id);
                    });
                  }}
                />
                {'\u{00A0}'}
                <pre style={{ display: 'inline-block', margin: 0, textOverflow: 'ellipsis', overflowX: 'hidden' }} >
                  {`${asset.name}\n${DateTime.fromISO(asset.updatedDate).toLocaleString(DateTime.DATETIME_MED)}`}
                </pre>
              </label>)}
          </fieldset>
        </Dialog>
      </div>
      Add assets from your library to the visualisation
      <p >
        This project will only be visible to other users if they have permissions for all assets listed here
      </p >

      {usedAssetsWithFallback.length <= 0 ?
        <InputPlaceholder
          placeholder='Select an asset from the library to add to the visualisation'
          title='Add an asset'
          onClick={() => { assetPickerDialogRef.current?.show(); }}
        /> :
        <div>
          {usedAssetsWithFallback.map(asset =>
            <span key={asset.vrmodel}>
              <strong >
                {asset.shortName}
              </strong>
              <Button type='button' onClick={() => {
                setUsedAssets(v => (v ?? project.actions.filter(isAddDataAction)).filter(x => x.vrmodel !== asset.vrmodel));
              }} >Remove</Button>
            </span>)}
        </div>
      }
    </div>
  </div>;
}
