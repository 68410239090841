import Alert from "../../src/components/Alert";
import Input from "../../src/components/Input";
import Spinner from "../../src/components/Spinner";
import type { FileState } from './FileState';
import { useMemo, type ComponentPropsWithoutRef, type HTMLAttributes } from 'react';
import FieldSet from '../../src/components/FieldSet';

const MAIN_EXTENSIONS = ["vrnative", "vrtext", "pvz", "pvs", "dd", "jt"];

export default function MainFileSelector({ files, ...props }:
  { files: ReadonlyMap<string, FileState>; } &
  Omit<ComponentPropsWithoutRef<'input'>, keyof HTMLAttributes<unknown> | 'type' | 'defaultChecked' | 'value' | 'required'>) {
  const possibleMainFiles = useMemo(() => !files || files.size <= 0 ? [] :
    [...files].filter(([path]) => {
      path = path.toLowerCase();
      return MAIN_EXTENSIONS.some((v) => { return path.endsWith(v); });
    }).map(v => v[0]), [files]);

  const defaultMainFile = possibleMainFiles.length > 0 ? possibleMainFiles[0] : undefined;

  return <>
    <br />
    <strong>Uploaded Files</strong>
    <p>These are the files that have been uploaded, or are currently being uploaded. Please select the main file to be imported.</p>
    {possibleMainFiles.length > 1 &&
      <>
        <Alert severity="warning">{`Multiple possible roots detected, combining assets should be done at project level.`}</Alert>
        <br />
      </>
    }
    <FieldSet style={{gridAutoRows:'2.25em'}} >
      {[...files].map(([path, state]) => {
        return <label
          key={path}
          style={state.type === 'pending' ? { opacity: 0.5 } : undefined}
        >
          <Input
            type='radio'
            defaultChecked={path === defaultMainFile}
            value={path}
            required
            {...props}
          />
          {path}
          {state.error && <Alert style={{ marginLeft: 'auto' }} severity="warning">{state.error}</Alert>}
          {state.type === 'uploading' && <Spinner />}
        </label>;
      })}
    </FieldSet>
  </>;
}
