import { createContext } from "react";
import type Alert from "./Alert";

export interface ToasterContext {
  showToast: (message: string, opts: {
    type: Parameters<typeof Alert>[0]['severity'];
  }) => void;
}

const ToastContext = createContext<ToasterContext | null>(null);

export default ToastContext;