export default function ChunkFile(file: File, chunkSize: number) {
  const totalChunks = Math.ceil(file.size / chunkSize);
  const path = `${file.webkitRelativePath}/${file.name}`;

  const chunks = Array(totalChunks).fill(0).map((_, index) => {
    const startOffset = index * chunkSize;
    const length = Math.min(file.size - startOffset, chunkSize);
    return {
      data: file.slice(startOffset, startOffset + length),
      length,
      index: index + 1
    };
  });

  return {
    totalChunks,
    chunkSize,
    path,
    chunks
  }
}
