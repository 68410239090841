import type Profile from "../../pages/types/Profile";
import type UserAuth from "../../pages/types/UserAuth";
import type ParticipationInfo from "../types/ParticipationInfo";
import { ActorType } from "../types/ParticipationInfo";

// Method that will request from collaboration session api to join a session for a user.
export default async function JoinSessionRequest(user: UserAuth&Profile, abortSignal: AbortSignal, sessionId?: string, type?: ActorType): Promise<ParticipationInfo> {
  // if we are missing session id then no participation info
  if (!sessionId)
    throw new Error("No session id? Is it running locally?");
  const userId = user.sub;

  const undefinedParticipation = {
    cullerHost: import.meta.env.VIRTALIS_CULLING_ACTOR_HOST,
    treeHost: import.meta.env.VIRTALIS_TREE_ACTOR_HOST,
    sessionId,
    userId,
  };

  const sessionApiUri = import.meta.env.VIRTALIS_COLLABORATION_SESSION_PATH;

  // request to join a session
  const instigatorUserId = user.sub;
  const actorType = type ?? ActorType.Culling;
  const data = {
    sessionId,
    userId,
    instigatorUserId,
    actorType
  };
  console.log(data);

  // request to add user to session
  const participationInfo = await fetch(`${sessionApiUri}/user`, {
    method: 'POST',
    signal: abortSignal,
    headers: {
      'Authorization': `Bearer ${user.access_token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then((json: { sessionId?: string, actorId?: string, participationId?: string; }) => {
      console.log(`Joined session ${json.sessionId}, assigned to actor ${json.actorId} with participant id ${json.participationId}`);
      return {
        participationId: json.participationId,
        userId,
        sessionId: json.sessionId,
        cullerHost: import.meta.env.VIRTALIS_ACTOR_PREFIX + json.actorId,
        treeHost: import.meta.env.VIRTALIS_BROKER_PREFIX + json.sessionId,
      } satisfies ParticipationInfo;
    })
    .then((info) => {
      // grab session id from url to generate the actor's id so the client can connect.  
      sessionId ?? console.warn('Session id is not set in the url parameters, client will have a problem connecting to correct actor!');
      // no session id? go back to artifact listing app
      sessionId ?? location.replace(`${location.protocol}//${location.host}/`);
      // no host? then join session process came back emtpy...
      // this could happen when you attempt to join an innactive session
      // need to redirect back to listing page
      // TODO: find a frienlier way to redirect user to listing page like 
      // redirect to a page that notifies user that session ended and then take him to listing
      info.cullerHost ?? location.replace(`${location.protocol}//${location.host}/`);

      // calculate actual host
      info.cullerHost = import.meta.env.VIRTALIS_CULLING_ACTOR_HOST ?? window.location.origin.replace(/^http/, 'ws').replace(/^https/, 'wss') + '/' + info.cullerHost;
      info.treeHost = import.meta.env.VIRTALIS_TREE_ACTOR_HOST ?? window.location.origin.replace(/^http/, 'ws').replace(/^https/, 'wss') + '/' + info.treeHost;
      return info;
    })
    .catch(() => {
      return undefinedParticipation;
    });

  return participationInfo;
}

export async function VerifySessionStateRequest(user: UserAuth, abortSignal: AbortSignal, sessionId?: string): Promise<boolean> {
  // if we are missing session id then no participation info
  if (!sessionId)
    throw new Error("No session id? Is it running locally?");

  enum SessionStates {
    Unknown,
    Stopped,
    Starting,
    Ready
  }

  let sessionState = SessionStates.Unknown;
  sessionState = await fetch(`${import.meta.env.VIRTALIS_COLLABORATION_SESSION_PATH}/state/session/${sessionId}`, {
    method: "GET",
    signal: abortSignal,
    headers: {
      'Authorization': `Bearer ${user.access_token}`,
      'Content-Type': 'application/json',
    }
  })
    .then(response => response.json())
    .then((json: { status: SessionStates; }) => json.status);

  if (sessionState === SessionStates.Ready) {
    console.log("Session verified to be ready");
    return true;
  }

  throw new Error("Session not ready yet!");
}
