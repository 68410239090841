import Editor from "@monaco-editor/react";
import ProjectContext, { type IProjectState } from "./ProjectContext";
import { useContext, useState, type CSSProperties } from "react";
import ProjectSchema from "./ProjectSchema.json";
import ProjectSchemaUrl from "./ProjectSchema.json?url";
import UsingDarkTheme from "../utils/UsingDarkTheme";

export default function Advanced() {
  const project = useContext(ProjectContext);
  const darkTheme = UsingDarkTheme();
  const [invalid, setInvalid] = useState(false);

  return <>
    <div style={{ position: 'relative', flex: 1 }} >
      <Editor
        theme={darkTheme ? 'vs-dark' : 'light'}
        language='json'
        onMount={(_, m) => {
          m.languages.json.jsonDefaults.setDiagnosticsOptions({
            ...m.languages.json.jsonDefaults.diagnosticsOptions,
            validate: true,
            schemaValidation: 'warning',
            schemas: [{
              uri: ProjectSchemaUrl,
              fileMatch: ['*.config.json'],
              schema: ProjectSchema
            }]
          });
        }}
        wrapperProps={{
          style: {
            display: 'flex',
            position: 'absolute',
            inset: 0,
            borderStyle: 'solid',
            borderColor: invalid ? 'red' : 'transparent'
          } satisfies CSSProperties
        }}
        options={{
          minimap: { enabled: false },
          scrollBeyondLastLine: false
        }}
        path='.config.json'
        value={JSON.stringify(project, undefined, 2)}
        onChange={(v) => {
          if (!v)
            return;
          try {
            const o = JSON.parse(v) as IProjectState;
            project.setProjectState(o);
            setInvalid(false);
          } catch { setInvalid(true);  }
        }}
      />
    </div>
  </>;
}
