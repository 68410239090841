import { useContext } from "react";
import css from './TreeNode.module.scss';
import TreeContext from "./TreeContext";
import EnabledIcon from './EnabledIcon';
import TreeNodeLabel from './TreeNodeLabel';
import type { VRTreeNode } from './VRTreeNode';

export function TreeNode({ id, name, metaName, children, localEnabled }: VRTreeNode) {
  const context = useContext(TreeContext);
  const subscribe = context?.subscribe;
  const unsubscribe = context?.unsubscribe;
  const updateEnabled = context?.updateEnabled;

  return (
    <>
      <li key={id}>
        <div className={css[`tree-text${localEnabled === false ? "-disabled" : ""}`]} onClick={(e) => { e.stopPropagation(); }}>
          {children &&
            <div
              className={`${css["expand-contract"]} ${Array.isArray(children) ? css["open"] : css["closed"]}`}
            onClick={() => {
              if (Array.isArray(children)) {
                if(unsubscribe)
                  unsubscribe(id);
              } else {
                if(subscribe)
                  subscribe(id);
              }
            }}
            />
          }
          {
            updateEnabled ?
              <EnabledIcon enabled={localEnabled !== false} type={metaName} updateFunc={() => { updateEnabled(id, !localEnabled); }} /> : <></>
          }
          <TreeNodeLabel id={id} name={name} />
        </div>

        {
          Array.isArray(children) &&
          (children.length > 0 ?
            <ul>
              {children.map((child) => (
                <TreeNode
                  key={child.id}
                  {...child}
                />
              ))}
            </ul> :
            <>
              <span style={{
                fontSize: 'small',
                padding: '1.5rem',
              }} >Loading</span>
            </>)
        }
      </li>
    </>
  );
}