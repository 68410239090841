import { useState, useEffect, useContext } from 'react';
import { TreeNode } from "./TreeNode";
import css from './Tree.module.scss';
import TreeContext, { type TreeFunction } from './TreeContext';
import Panel from './Panel';
import PacketWriter from '../../src/net/PacketWriter';
import PacketReader from '../../src/net/PacketReader';
import { vec3 } from 'gl-matrix';
import ControlContext, { type BlinkToCallback, type RemoteFuncCall } from './ControlContext';
import Participate from '../Participate';
import { ActorType } from '../../src/types/ParticipationInfo';
import wrapAngleWithinHalfPI from '../../src/utils/WrapAngleWithinHalfPI';
import TabGroup from './TabGroup';
import TreeNodeLabel from './TreeNodeLabel';
import PageContext from '../PageContext';
import type { VRTreeNode, Enabled } from './VRTreeNode';

const enum ClientToTreeActor {
  Root,
  Subscribe,
  Unsubscribe,
  UpdateEnabled,
  BlinkTo,
}
const enum TreeActorToClient {
  SetNodeInfo,
  NodeUpdated,
  AddChild,
  RemoveChild,
  AddViewpoint,
  RemoveViewpoint,
  ReparentViewpoint,
  RenameViewpoint,
  BlinkTo,
  BlinkToView,
}

interface ViewpointNode {
  id: number,
  name: string,
  path: string,
  position: string;
}

function compareViewpoints(a: ViewpointNode, b: ViewpointNode) {
  const aArr = a.position.split("/");
  const bArr = b.position.split("/");

  const lengthDiff = aArr.length - bArr.length;

  if (lengthDiff === 0) {
    for (let i = 0; i < aArr.length; i++) {
      const aVal = Number("0x" + aArr[i]);
      const bVal = Number("0x" + bArr[i]);

      const valDiff = aVal - bVal;

      if (valDiff === 0) {
        continue;
      }

      return valDiff;
    }
    return 0;
  }

  return lengthDiff;
}

function insertViewpoint(arr: ViewpointNode[], viewpoint: ViewpointNode) {
  // we have already added this object don't do it again
  if (arr.indexOf(viewpoint) > -1)
    return arr;

  const idx = arr.findIndex(obj => compareViewpoints(obj, viewpoint) >= 0);

  if (idx === -1)
    arr.push(viewpoint);
  else
    arr.splice(idx, 0, viewpoint);

  return arr;
}

function insertNode(arr: VRTreeNode[], node: VRTreeNode, siblingId: number) {
  if (arr.indexOf(node) > -1)
    throw new Error("Adding already existing node");

  const idx = arr.findIndex(obj => obj.id === siblingId);
  arr.splice((idx < 0 ? 0 : (idx + 1)), 0, node);

  return arr;
}

function removeNode(arr: VRTreeNode[], id: number) {
  const idx = arr.findIndex((node) => node.id === id);
  if (idx === -1)
    throw new Error("couldn't find child to remove");

  const returnArr = arr.splice(idx, 1);
  return returnArr[0];
}

function intToEnabled(val: number): Enabled {
  if (val > 0)
    return true;
  else if (val < 0)
    return 'ForceEnabled';
  else
    return false;
}

interface Alice {
  root: VRTreeNode;
  nodes: Map<number, VRTreeNode>;
}

export default function Tree() {
  const user = useContext(PageContext);
  const [blinkTo, setBlinkToFunctionCallback] = useState<BlinkToCallback>({});
  const [fadeIn, setFadeInFunctionCallback] = useState<RemoteFuncCall>({});
  const { setBlinkToCallback, setFadeInCallback } = useContext(ControlContext);
  const [state, setState] = useState<string | null>(null);
  const [root, setRoot] = useState<Alice>();
  const [subscriptionFunction, setCallback] = useState<TreeFunction>(null);
  const [selectedTabIdx, setSelectedTabIdx] = useState<number>(0);
  const [viewPointList, setViewPointList] = useState<ViewpointNode[]>([]);

  useEffect(() => {
    // this should be an array or map or something so it can store multiple values
    setBlinkToCallback(() => setBlinkToFunctionCallback);

    return () => {
      setBlinkToCallback(null);
    };
  }, [setBlinkToCallback]);

  useEffect(() => {
    setFadeInCallback(() => setFadeInFunctionCallback);

    return () => {
      setFadeInCallback(null);
    };
  }, [setFadeInCallback]);

  useEffect(() => {
    if (!user || !blinkTo || !fadeIn)
      return;
    const abort = new AbortController();
    const func = async () => {
      const participation = await Participate(user, abort.signal, ActorType.Tree);
      console.log("Creating tree participation!");
      const protocolVersion = "TREE_V0";
      const parameters = new URLSearchParams();
      if (participation.participationId)
        parameters.append('participant', participation.participationId);
      const searchParams = parameters.toString();
      const host = participation.treeHost + (searchParams ? `?${searchParams}` : '');
      console.log(`Tree participation host ${host}`);

      const maxReconnectAttempts = 30;
      let remainingAttempts = maxReconnectAttempts;

      const connect = (host: string, protocol: string) => {
        const reconnectInterval = 2000;
        
        const webSocket = new WebSocket(host, protocol);
        webSocket.binaryType = 'arraybuffer';

        const nodes = new Map<number, VRTreeNode>();
        // Continue with WebSocket initialization and other logic
        webSocket.onopen = () => {
          {
            remainingAttempts = maxReconnectAttempts;
            console.log("Tree participation requests root node first");
            // requests root node first
            const packet = new PacketWriter();
            packet.type = ClientToTreeActor.Root;
            webSocket.send(packet.buffer);
          }

          const thing: TreeFunction = {
            subscribe: (id) => {
              const packet = new PacketWriter();
              packet.type = ClientToTreeActor.Subscribe;
              packet.setUint32(id);

              webSocket.send(packet.buffer);
            },
            unsubscribe: (id) => {
              const packet = new PacketWriter();
              packet.type = ClientToTreeActor.Unsubscribe;
              packet.setUint32(id);
              webSocket.send(packet.buffer);
              // find the node in the map and set its children to true
              const node = nodes.get(id);
              if (!node)
                throw new Error("trying to unsubscribe from a non-existant node");
              node.children = true;
              setRoot((currentRoot) => {
                if (!currentRoot)
                  throw new Error("Trying to unsubscribe from a node before we have a root node");
                currentRoot.nodes = nodes;

                return { ...currentRoot };
              });
            },
            updateEnabled: (id, enabled) => {
              const packet = new PacketWriter();
              packet.type = ClientToTreeActor.UpdateEnabled;
              packet.setUint32(id);
              packet.setBool(enabled);
              webSocket.send(packet.buffer);
            },
            blinkTo: (id) => {
              if (fadeIn.handle)
                fadeIn.handle();
              const packet = new PacketWriter();
              packet.type = ClientToTreeActor.BlinkTo;
              packet.setUint32(id);

              webSocket.send(packet.buffer);
            },
          };

          setCallback((v: TreeFunction) => abort.signal.aborted ? v : thing);
        };

        webSocket.onmessage = (ev: MessageEvent<ArrayBuffer>) => {
          if (abort.signal.aborted)
            return;
          const packet = new PacketReader(ev.data);
          switch (packet.type as TreeActorToClient) {
            case TreeActorToClient.SetNodeInfo: {
              const id = packet.getUint32();
              const name = packet.getString();
              const metaName = packet.getString();
              const children = packet.getUint8() ? true : undefined;
              const rootNode: VRTreeNode = {
                id,
                name,
                metaName,
                children,
                localEnabled: 'ForceEnabled',
              };
              nodes.set(id, rootNode);
              setRoot(v => abort.signal.aborted ? v : {
                root: rootNode,
                nodes
              });
              setState(v => abort.signal.aborted ? v : null);
              break;
            }
            case TreeActorToClient.AddChild: {
              const parentId = packet.getUint32();
              const id = packet.getUint32();
              const name = packet.getString();
              const metaName = packet.getString();
              const children = packet.getUint8() ? true : undefined;
              const siblingId = packet.getUint32();
              const localEnabled = intToEnabled(packet.getInt8());

              // find parent in map
              const parent = nodes.get(parentId);
              if (!parent)
                throw new Error("Trying to add child to non-existant parent");
              const node: VRTreeNode = {
                id,
                name,
                metaName,
                children,
                localEnabled
              };

              // insert into the correct position in child array of said parent
              const childArr = parent.children;
              if (!Array.isArray(childArr)) {
                // if the childArr isn't an array we must've expanded the node and this is the first child to be added
                // This does mean that if you have a bad internet connection you won't see any visual respose to an expansion anymore
                // as before you'd have the loading text
                parent.children = [node];
              } else {
                insertNode(childArr, node, siblingId);
              }
              nodes.set(id, node);
              setRoot((currentRoot) => {
                if (!currentRoot)
                  throw new Error("Trying to add a node before we have a root node");
                currentRoot.nodes = nodes;

                return { ...currentRoot };
              });
              break;
            }
            case TreeActorToClient.RemoveChild: {
              const parentId = packet.getUint32();
              const id = packet.getUint32();

              const parent = nodes.get(parentId);
              // TODO figure out if its an issue we can't find the parent
              if (!parent)
                break;

              const childArr = parent.children;
              // TODO figure out if its an issue the children value isn't an array
              if (!Array.isArray(childArr))
                break;

              removeNode(childArr, id);
              if (childArr.length === 0)
                parent.children = undefined;

              setRoot((currentRoot) => {
                if (!currentRoot)
                  throw new Error("Trying to remove a node before we have a root node");
                currentRoot.nodes = nodes;

                return { ...currentRoot };
              });

              break;
            }
            case TreeActorToClient.AddViewpoint: {
              const id = packet.getUint16();
              const name = packet.getString();
              const path = packet.getString();
              const position = packet.getString();

              const treeNode: ViewpointNode = {
                id,
                name,
                path,
                position,
              };
              setViewPointList((list) => abort.signal.aborted ? list : insertViewpoint(list, treeNode));

              break;
            }
            case TreeActorToClient.RemoveViewpoint: {
              const id = packet.getUint32();

              setViewPointList((list) => {
                if (abort.signal.aborted)
                  return list;

                return list.filter(node => node.id !== id);
              });
              break;
            }
            case TreeActorToClient.ReparentViewpoint: {
              const id = packet.getUint32();
              const path = packet.getString();
              const position = packet.getString();

              setViewPointList((list) => {
                if (abort.signal.aborted)
                  return list;

                const idx = list.findIndex((node) => node.id === id);
                if (idx < 0)
                  throw new Error("Trying to reparent a viewpoint that doesn't exist");

                list[idx].path = path;
                list[idx].position = position;
                list.sort(compareViewpoints);

                return [...list];
              });
              break;
            }
            case TreeActorToClient.RenameViewpoint: {
              const id = packet.getUint32();
              const name = packet.getString();

              setViewPointList((list) => {
                if (abort.signal.aborted)
                  return list;

                const idx = list.findIndex((node) => node.id === id);
                if (idx < 0)
                  throw new Error("Trying to rename a viewpoint that doesn't exist");

                list[idx].name = name;

                return [...list];
              });

              break;
            }
            case TreeActorToClient.NodeUpdated: {
              const parentId = packet.getUint32();
              const id = packet.getUint32();
              const name = packet.getString();
              const metaName = packet.getString();
              const children = packet.getUint8() ? true : undefined;
              const siblingId = packet.getUint32();
              const localEnabled = packet.getInt8();

              const parent = nodes.get(parentId);
              if (!parent)
                throw new Error("Failed to update node");

              const childArr = parent.children;
              if (!Array.isArray(childArr))
                throw new Error("Failed to update node");
              const node = removeNode(childArr, id);
              node.name = name;
              node.metaName = metaName;
              node.localEnabled = intToEnabled(localEnabled);
              if (!Array.isArray(node.children)) {
                node.children = children;
              }
              insertNode(childArr, node, siblingId);

              setRoot((currentRoot) => {
                if (!currentRoot)
                  throw new Error("Trying to upadate a node before we have a root node");
                currentRoot.nodes = nodes;

                return { ...currentRoot };
              });
              break;
            }
            case TreeActorToClient.BlinkTo: {
              if (blinkTo.handle) {
                const min = vec3.fromValues(packet.getFloat32(), packet.getFloat32(), packet.getFloat32());
                const max = vec3.fromValues(packet.getFloat32(), packet.getFloat32(), packet.getFloat32());
                blinkTo.handle([min, max], false);
              }
              else
                console.log("BlinkTo function not defined");
              break;
            }
            case TreeActorToClient.BlinkToView: {
              if (blinkTo.handle) {
                const pos = vec3.fromValues(packet.getFloat32(), packet.getFloat32(), packet.getFloat32());
                const rot = vec3.create();
                rot[1] = wrapAngleWithinHalfPI(packet.getFloat32());
                rot[0] = packet.getFloat32();
                rot[2] = packet.getFloat32();

                blinkTo.handle([pos, rot], true);
              }
              else
                console.log("BlinkTo function not defined");
              break;
            }
            default:
              console.warn("Unknown packet type recieved");
          }
        };

        webSocket.onerror = (error) => {
          setState(v => abort.signal.aborted ? v : error.type);
          if (!abort.signal.aborted) {
            console.error("Tree participation socket onerror error:", error);
          }
          else {
            console.warn("Tree participation socket onerror aborted");
          }
        };

        webSocket.onclose = () => {
          setState(v => abort.signal.aborted ? v : "Connection closed");
          if (!abort.signal.aborted) {
            if (remainingAttempts > 0) {
              remainingAttempts--;
              console.warn(`Tree participation socket onclose closed. Retrying attempts ${maxReconnectAttempts - remainingAttempts}...`);
              setState(`Connection failed. Retrying...${maxReconnectAttempts - remainingAttempts}`);
              setTimeout(() => connect(host, protocol), reconnectInterval);
            }
            else {
              console.error(`Tree participation socket closed after ${maxReconnectAttempts} attempts`);
            }
          }
          else {            
            console.warn("Tree participation socket onclose aborted");
          }
        };

        return webSocket;
      };

      const webSocket = connect(host, protocolVersion);
      
      return () => {
        console.log("closing tree connection");
        webSocket.close();
      };
    };

    setState('Loading tree...');
    const result = func();

    return () => {
      abort.abort();
      result.then(dispose => dispose()).catch(() => { });
      setState(null);
      setCallback(null);
      console.log("emptying view point list");
      setViewPointList([]);
    };
  }, [user, blinkTo, fadeIn]);

  return (
    <>
      {
        <Panel
          className={`${css["tree-overlay"]}`}
          title={<TabGroup labels={["Product tree", "Viewpoints"]} onTabChange={setSelectedTabIdx} />}
          lockKey='treeLock'
          alignment='left'
        >
          {root && subscriptionFunction
            ?
            <TreeContext.Provider value={subscriptionFunction}>
              <div className={css["clt"]} hidden={selectedTabIdx !== 0}>
                <ul>
                  <TreeNode {...root.root} />
                </ul>
              </div>
              <div hidden={selectedTabIdx !== 1}>
                {
                  viewPointList.length > 0 ?
                    <ul style={{
                      listStyleType: "none",
                      padding: 0,
                      margin: 0,
                    }}>
                      {viewPointList.map((item) => (
                        <li title={item.path + item.name} key={item.id}>
                          <TreeNodeLabel id={item.id} name={item.name} />
                        </li>
                      ))}
                    </ul> :
                    <span style={{ padding: '1rem' }}>No viewpoints</span>
                }
              </div>
            </TreeContext.Provider>
            :
            <>
              {state && <span style={{ padding: '1rem' }} >{state}</span>}
            </>
          }
        </Panel>
      }
    </>
  );
}
