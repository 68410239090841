import type UserAuth from "../types/UserAuth";
import ChunkFile from "../utils/ChunkFile";

const CHUNK_SIZE = 4194304;// 4 MiB

export default async function UploadChunked(file: File, folderId: string, user: UserAuth, onProgress: (v: number) => void, signal?: AbortSignal) {
  const chunked = ChunkFile(file, CHUNK_SIZE);
  let retried = 0;
  while (chunked.chunks.length > 0 && (!signal || !signal.aborted)) {
    const chunk = chunked.chunks.pop();
    if (!chunk)
      throw new Error("Unexpected undefined chunk value");

    const formData = new FormData();
    formData.append('file', chunk.data, chunked.path);

    const res = await fetch(`/hub/api/Folder/UploadChunked?${new URLSearchParams({
      resumableChunkNumber: `${chunk.index}`,
      resumableTotalChunks: `${chunked.totalChunks}`,
      resumableChunkSize: `${chunked.chunkSize}`,
      resumableTotalSize: `${file.size}`,
      resumableIdentifier: `${chunked.path}-${file.lastModified}-${file.size}`,
      resumableFilename: file.name,
      resumableRelativePath: chunked.path,
      folderId: folderId,
      resumableCurrentChunkSize: `${chunk.length}`,
    }).toString()}`, {
      headers: { 'Authorization': `Bearer ${user.access_token}` },
      method: 'POST',
      body: formData,
      signal,
      priority: 'low'
    }).catch(()=>null);

    if (res && res.ok) {
      onProgress(chunk.length);
      retried = 0;
    }
    else {// it failed re-queue the chunk
      chunked.chunks.push(chunk);
      retried++;
      if (retried > 5)
        throw new Error("Failed to upload chunk");
    }
  }

  return true;
}
