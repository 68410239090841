import Translate from "../components/Translate";
import { useCallback, useContext, useEffect, useState } from "react";
import PageContext from "../PageContext";
import { DateTime } from "luxon";
import HubAside from "../components/HubAside";
import AutoFetch from "../utils/AutoFetch";
import css from './JobsPage.module.scss';
import Card from "../../src/components/Card";
import CardList from "../../src/components/CardList";
import ToastContext from "../../src/components/ToastContext";
import PageHeader from "../../src/components/PageHeader";
import Button from "../../src/components/Button";
import Alert from "../../src/components/Alert";
import Spinner from "../../src/components/Spinner";
import PageContent from "../../src/components/PageContent";

interface IJobs {
  totalResults: number;
  jobs: {
    jobId: string;
    filename: string;
    failureReason: string;
    createdDate: string;
    lastModifiedDate: string;
    status: string;
    type: string;
    retrying: boolean;
    stageProgress: number;
    artifactIds: string[];
  }[];
}

const fetchConfig = {
  retryCount: 3,
  refreshDelay: 10000,
  autoRefresh: true
};

export default function JobsPage() {
  const user = useContext(PageContext);
  const toaster = useContext(ToastContext);
  const [data, setItems] = useState<IJobs>();
  const [error, setError] = useState<string>();
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    let abandoned = false;
    setError(v => abandoned ? v : '');
    setFetching(v => abandoned ? v : true);
    let gotResults = false;
    const stopFetch = AutoFetch(
      `hub/api/jobs`,
      () => ({ headers: { 'Authorization': `Bearer ${user?.access_token}` } }),
      (data) => {
        gotResults = true;
        setFetching(v => abandoned ? v : false);
        setItems(v => abandoned ? v : data as IJobs);
      },
      () => {
        setFetching(v => abandoned ? v : false);
        setError(v => abandoned ? v : `Failed to ${gotResults ? "refresh" : "acquire"} jobs.`);
      },
      fetchConfig
    );

    return () => {
      abandoned = true;
      stopFetch();
    };
  }, [setItems, user]);

  const requestCancel = useCallback((filename: string, jobId: string) => {
    const confirmed = confirm(`The job '${filename}' is still in progress. Are you sure you want to cancel it?`);
    if (!confirmed) return;

    const controller = new AbortController();
    setTimeout(() => { controller.abort(); }, 12000);
    // Call the back end to cancel the job
    fetch(`hub/api/jobs/${jobId}/cancel`, {
      method: 'put',
      signal: controller.signal,
      headers: { 'Authorization': `Bearer ${user?.access_token}` }
    }).catch(() => {
      toaster?.showToast("Failed to cancel job please try again", { type: 'warning' });
    });
  }, [toaster, user]);

  return <PageContent> <HubAside current='jobs' />
    <PageHeader
      title={Translate("Job Status")}
      subTitle={Translate("An overview of data that has been, or is currently being processed by Virtalis Reach")}
    />

    <div style={{ margin: 24 }} >
      {error && <Alert severity="warning">{`${error}. Please refresh the page to try again.`}</Alert>}
      {fetching && <Spinner />}
      {data && Object.keys(data.jobs).length > 0 && (
        <CardList layout='wide'>
          {data.jobs.map((a) => {
            return <Card
              key={a.jobId}
              image={false && <span className={css.jobImage} style={{
                backgroundColor: a.type === 'ProjectPublished' ? 'palegoldenrod' : 'palevioletred',
              }}>{a.type === 'ProjectPublished' ? 'Project' : 'Asset'}</span>}
              header={<>
                <strong>{a.filename}</strong>
                &ensp;
                <small>{a.type === 'ProjectPublished' ? 'Project' : 'Asset'}</small>
              </>}
              status={
                <Alert
                  className={`${css.jobStatus}`}
                  severity={
                  a.status === 'Complete' ? 'success' :
                      a.status === 'Error' || a.status === 'Abandoned' ? 'error' :
                      a.status === 'Cancelled' || a.retrying === true ? 'warning' : 'info'
                } >
                  {a.status === 'CancellationRequested' ? 'Cancelling' : a.status === 'ProcessingAction' ? 'Processing' : a.status}
                </Alert>
              }
              actions={<>
                {a.stageProgress !== 100 && a.stageProgress !== 0 &&
                  <progress className={css.jobProgressPanel} value={a.stageProgress} max={100} />
                }

                {(a.status !== 'Complete' &&
                  a.status !== 'Abandoned' &&
                  a.status !== 'Cancelled' &&
                  a.status !== 'Error')
                  ? <Button
                    sm
                    title={`Stop processing the job '${a.filename}'`}
                    onClick={() => { requestCancel(a.filename, a.jobId); }}
                    disabled={a.status === 'CancellationRequested'}
                  >Cancel</Button>
                  : (a.artifactIds[0] && <Button
                    sm
                    href={`loading?artifactId=${a.artifactIds[0]}`}
                    target="_blank"
                    title="Open this visualisation in a new tab"
                    rel="noopener noreferrer"
                  >View</Button>)
                }
              </>}
            >
              <span>
                <small>Created: {DateTime.fromISO(a.createdDate).toLocaleString(DateTime.DATETIME_MED)}</small>
                <small>, Last modified: {DateTime.fromISO(a.lastModifiedDate).toLocaleString(DateTime.DATETIME_MED)}</small>
              </span>
              {a.failureReason && <div className={css.jobErrorText}>{a.failureReason}</div>}
            </Card>;
          })}
        </CardList>
      )}
    </div>
  </PageContent>;
}
