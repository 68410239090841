import { useContext, useEffect, useState } from 'react';
import ControlContext, { type SetDebugAnimationCallback } from './ControlContext';

export default function AnimationControls() {
  const [setDebugAnimation, setDebugFunctionCallback] = useState<SetDebugAnimationCallback>({});
  const { setDebugAnimationCallback } = useContext(ControlContext);

  const [debugAnimEnabled, setDebugAnimEnabled] = useState<boolean>(false);
  const [debugAnimationDelayTime, setDebugAnimationDelayTime] = useState<number>(5000);
  const [debugAnimationRotationDuration, setDebugAnimationRotationDuration] = useState<number>(12500);
  const [debugAnimationZoomTime, setDebugAnimationZoomTime] = useState<number>(5000);
  const [debugAnimationFar, setDebugAnimationFar] = useState<number>(5);
  const [debugAnimationNear, setDebugAnimationNear] = useState<number>(2);
  const [debugAnimationPosX, setDebugAnimationPosX] = useState<number>(0);
  const [debugAnimationPosY, setDebugAnimationPosY] = useState<number>(0);
  const [debugAnimationPosZ, setDebugAnimationPosZ] = useState<number>(0);

  useEffect(() => {
    setDebugAnimationCallback(() => setDebugFunctionCallback);

    return () => {
      setDebugAnimationCallback(null);
    };
  }, [setDebugAnimationCallback]);

  useEffect(() => {
    if (setDebugAnimation.handle) {
      if (debugAnimEnabled)
        setDebugAnimation.handle({
          debugAnimationDelayTime,
          debugAnimationRotationDuration,
          debugAnimationZoomTime,
          debugAnimationFar,
          debugAnimationNear,
          debugAnimationPosX,
          debugAnimationPosY,
          debugAnimationPosZ,
        }); // not null but haven't gotten around to it yet
      else
        setDebugAnimation.handle(null);
    }
  }, [debugAnimEnabled, debugAnimationDelayTime, debugAnimationFar, debugAnimationNear, debugAnimationPosX, debugAnimationPosY, debugAnimationPosZ, debugAnimationRotationDuration, debugAnimationZoomTime, setDebugAnimation]);

  useEffect(() => {
    if (setDebugAnimation.handle) {
      if (debugAnimEnabled)
        setDebugAnimation.handle({
          debugAnimationDelayTime,
          debugAnimationRotationDuration,
          debugAnimationZoomTime,
          debugAnimationFar,
          debugAnimationNear,
          debugAnimationPosX,
          debugAnimationPosY,
          debugAnimationPosZ,
        }); // not null but haven't gotten around to it yet
    }
  }, [debugAnimationDelayTime, debugAnimationRotationDuration, debugAnimationZoomTime, debugAnimationFar, debugAnimationNear, debugAnimationPosX, debugAnimationPosY, debugAnimationPosZ, setDebugAnimation, debugAnimEnabled]);

  return <>
    <div style={{ position: 'relative' }} >
      <div>Animated Debug Camera</div>
      <form className="reach-settings">
        <label><span>Enabled</span><br /><small>Animated debug camera that orbits about a centre point</small></label>
        <input className="switch" type="checkbox" onClick={(cb) => { setDebugAnimEnabled((cb.target as HTMLInputElement).checked); }} />
        <label><span>Delay</span><br /><small>Time in milliseconds to wait before repeating the animation</small></label>
        <input id="nDebugAnimDelayTime" type="number" value={debugAnimationDelayTime} onChange={(cb) => { setDebugAnimationDelayTime(Number(cb.target.value)); }} />
        <label><span>Zoom time</span><br /><small>Time in milliseconds to go from min to max zoom</small></label>
        <input id="nDebugAnimZoomTime" type="number" value={debugAnimationZoomTime} onChange={(cb) => { setDebugAnimationZoomTime(Number(cb.target.value)); }} />
        <label><span>Rotation time</span><br /><small>Time in milliseconds to complete a full rotation</small></label>
        <input id="nDebugAnimRotationTime" type="number" value={debugAnimationRotationDuration} onChange={(cb) => { setDebugAnimationRotationDuration(Number(cb.target.value)); }} />
        <label><span>Zoom min</span><br /><small>Minimum distance in metres to orbit about the centre point</small></label>
        <input id="nDebugAnimNear" type="number" value={debugAnimationNear} onChange={(cb) => { setDebugAnimationNear(Number(cb.target.value)); }} />
        <label><span>Zoom max</span><br /><small>Maximum distance in metres to orbit about the centre point</small></label>
        <input id="nDebugAnimFar" type="number" value={debugAnimationFar} onChange={(cb) => { setDebugAnimationFar(Number(cb.target.value)); }} />
        <label><span>Centre point</span><br /><small>Centre point to orbit about (x, y, z)</small></label>
        <span style={{ display: "grid" }}>
          <input id="nDebugPosX" type="number" value={debugAnimationPosX} onChange={(cb) => { setDebugAnimationPosX(Number(cb.target.value)); }} />
          <input id="nDebugPosY" type="number" value={debugAnimationPosY} onChange={(cb) => { setDebugAnimationPosY(Number(cb.target.value)); }} />
          <input id="nDebugPosZ" type="number" value={debugAnimationPosZ} onChange={(cb) => { setDebugAnimationPosZ(Number(cb.target.value)); }} />
        </span>
      </form>
    </div>
  </>;
}