import type { PropsWithChildren, ReactNode } from "react";
import css from './PageHeader.module.scss';

export default function PageHeader(props: PropsWithChildren<{ title: ReactNode, subTitle?: string; }>) {
  return <header className={css['page-header']}>
    <span>
      <h1>{props.title}</h1>
      {props.subTitle && <span>{props.subTitle}</span>}
    </span>
    {props.children}
  </header>;
}