
function stringToHash(string: string) {
  let hash = 0;
  if (string.length == 0) return hash;
  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }

  return hash;
}

function hslToRgb(hue: number, sat: number, light: number) {
  let t2;
  hue = hue / 60;
  if (light <= 0.5) {
    t2 = light * (sat + 1);
  } else {
    t2 = light + sat - (light * sat);
  }
  const t1 = light * 2 - t2;
  const r = hueToRgb(t1, t2, hue + 2) * 255;
  const g = hueToRgb(t1, t2, hue) * 255;
  const b = hueToRgb(t1, t2, hue - 2) * 255;
  return { r: r, g: g, b: b };
}

function hueToRgb(t1: number, t2: number, hue: number) {
  if (hue < 0) hue += 6;
  if (hue >= 6) hue -= 6;
  if (hue < 1) return (t2 - t1) * hue + t1;
  else if (hue < 3) return t2;
  else if (hue < 4) return (t2 - t1) * (4 - hue) + t1;
  else return t1;
}

// Used to generate the users colour given their ID returns the colour in the HSV format
export function genColourHSL(userId: string): [number, number, number] {
  const seed = stringToHash(userId);
  const hue = (seed) % 360;

  return [hue, 65, 34];
}

// Used to generate the users colour given their ID returns the colour in the RGB format
export function genColourRGB(userId: string): [number, number, number] {
  const hsl = genColourHSL(userId);
  const { r, g, b } = hslToRgb(hsl[0], hsl[1] / 100, hsl[2] / 100);
  return [r / 255, g / 255, b / 255];
}
