
export default function AutoFetch(
  url: string,
  requestInit: ()=>Omit<RequestInit, 'signal'>,
  onComplete: (data: Awaited<ReturnType<Body['json']>>) => void,
  onAbandoned: VoidFunction,
  config: Readonly<{
    retryCount: number,
    refreshDelay: number,
    autoRefresh?: boolean,
    initialRetryDelay?: number;
  }>
) {
  const controller = new AbortController();
  let initialResultsAcquired = false;

  let retries = config.retryCount;
  const fetchJobs = () => {
    fetch(url, {
      ...requestInit(),
      signal: controller.signal
    }).then((response) => {
      if (!response.ok)
        throw new Error("Server failed to respond.");
      return response.json();
    }).then((items) => {
      retries = config.retryCount;
      initialResultsAcquired = true;
      onComplete(items);
    }).catch(() => {
      retries--;
    }).finally(() => {
      if (retries <= 0)
        onAbandoned();
      else if (!controller.signal.aborted && (config.autoRefresh || !initialResultsAcquired))
        fetchTimeoutId = setTimeout(fetchJobs, initialResultsAcquired ? config.refreshDelay : config.initialRetryDelay);
    });
  };
  let fetchTimeoutId = setTimeout(fetchJobs, 0);

  return () => {
    clearTimeout(fetchTimeoutId);
    controller.abort();
  };
}