import { useContext, useEffect, useState } from 'react';
import ControlContext, { type SetSettingsCallback } from './ControlContext';

export default function Settings() {
  const [updateSettings, setUpdateSettings] = useState<SetSettingsCallback>({});
  const [backfaceCullingEnabled, setBackfaceCullingEnabled] = useState(true);
  const [levelOfDetail, setLevelOfDetail] = useState(0);
  const { setSettingsCallback, statsMonitor } = useContext(ControlContext);

  useEffect(() => {
    setSettingsCallback(() => setUpdateSettings);

    return () => {
      setSettingsCallback(null);
    };
  }, [setSettingsCallback]);

  useEffect(() => {
    if (updateSettings.handle) {
      updateSettings.handle({
        backfaceCullingEnabled,
        levelOfDetail: -levelOfDetail,
      });
    }
  }, [backfaceCullingEnabled, levelOfDetail, updateSettings]);

  return <>
    <div style={{ position: 'relative' }} >
      <form className="reach-settings">
        <label><span>Backface culling</span> <br /> <small>Improves performance but can cause rendering mistakes</small></label>
        <input
          className="switch"
          type="checkbox"
          checked={backfaceCullingEnabled}
          onChange={(e) => setBackfaceCullingEnabled(e.target.checked)}>
        </input>

        <label><span>Level of detail</span> <br /> <small>use lower detail level to get a higher framerate</small></label>
        { /* We use negative ranges so the higher value is to the right */}
        <input
          type="range"
          list="markers"
          min={-2}
          max={0}
          step={1}
          value={levelOfDetail}
          onChange={(e) => setLevelOfDetail(e.target.valueAsNumber)}>
        </input>
        <datalist id="markers">
          <option value="-2"></option>
          <option value="-1"></option>
          <option value="0"></option>
        </datalist>

        <label><span>Developer mode</span> <br /> <small>Displays performance statistics and other details for development purposes</small></label>
        <input
          className="switch"
          type="checkbox"
          defaultChecked={statsMonitor?.active}
          onChange={(e) => {
            if (statsMonitor)
              statsMonitor.active = e.target.checked;
          }}>
        </input>
      </form>
    </div>
  </>;
}