import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import css from './Card.module.scss';

type CardProps = {
  image?: ReactNode;
  header?: ReactNode;
  subtitle?: ReactNode;
  status?: ReactNode;
  actions?: ReactNode;
  standalone?: {
    theme?: 'basic';
    layout?: 'standard' | 'wide';
  };
} & (ComponentPropsWithoutRef<'div'> | ComponentPropsWithoutRef<'a'>);

export default function Card({
  image,
  header,
  status,
  actions,
  children,
  standalone,
  ...props }: CardProps) {

  // add theme to className prop
  if (standalone)
    props.className += ` ${css['card']} ${css[`layout-${standalone.layout ?? 'standard'}`]} ${css[`style-${standalone.theme ?? 'basic'}`]}`;

  const Component: string = 'href' in props ? 'a' : 'div';

  return <Component {...props}>
    {image && <figure>{image}</figure>}
    {header && <header>{typeof header === 'string' ? <strong>{header}</strong> : header}</header>}
    {children && <p>{children}</p>}
    {status && <span>{status}</span>}
    {actions && <div>{actions}</div>}
  </Component>;
}
