import { useContext, useEffect, useState } from 'react';
import { FaPlus } from "react-icons/fa";
import PageContext from '../PageContext';
import HubAside from '../components/HubAside';
import AutoFetch from '../utils/AutoFetch';
import Card from '../../src/components/Card';
import CardList from '../../src/components/CardList';
import PageHeader from '../../src/components/PageHeader';
import Input from '../../src/components/Input';
import Select from '../../src/components/Select';
import Button from '../../src/components/Button';
import Alert from '../../src/components/Alert';
import Spinner from '../../src/components/Spinner';
import Pagination from '../../src/components/Pagination';
import PaginationWrapper from '../../src/components/PaginationWrapper';
import PageContent from '../../src/components/PageContent';
import AssetOrderBy from '../types/AssetOrderBy';
import type IAssets from '../types/IAssets';

const PAGE_SIZE = 10;

// auto refreshing can be irritating,
// just when you go to click a link
// the results shift down the page
const fetchConfig = {
  retryCount: 3,
  refreshDelay: 10000,
};

export default function LibraryPage() {
  const user = useContext(PageContext);
  const [sortOrder, setOrder] = useState<AssetOrderBy>(AssetOrderBy.NameASC);
  const [error, setError] = useState('');
  const [searchString, setSearchString] = useState('');
  const [items, setItems] = useState<IAssets>();
  const [fetching, setFetching] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pendingQuery, setPendingQuery] = useState<URLSearchParams>();
  const [currentQuery, setCurrentQuery] = useState<URLSearchParams>();

  useEffect(() => {
    // if we've changed the name filter or ordering
    // set us back to page 1 if we're not already on it
    let pageNumber = currentPage;
    if (
      pendingQuery &&
      pendingQuery?.get("PageNumber") !== "1" &&
      (
        pendingQuery.get("ModelNameFilter") !== searchString ||
        (pendingQuery.get("SortOrder") === 'ASC' && sortOrder !== AssetOrderBy.NameASC)
      )) {
      pageNumber = 1;
      setCurrentPage(1);
    }

    const query = new URLSearchParams({
      SortBy: 'name',
      SortOrder: sortOrder,
      PageNumber: pageNumber.toString(),
      PageSize: PAGE_SIZE.toString(),
      ModelNameFilter: searchString
    });

    // Only set the query if it is different from what we've already set
    if (query.toString() !== pendingQuery?.toString())
      setPendingQuery(query);
  }, [currentPage, currentQuery, sortOrder, pendingQuery, searchString]);

  useEffect(() => {
    if (!pendingQuery)
      return;

    let abandoned = false;
    setError(v => abandoned ? v : '');
    setFetching(v => abandoned ? v : true);
    let initialResultsAcquired = false;

    const stopFetch = AutoFetch(
      `hub/api/models?${pendingQuery.toString()}`,
      () => ({ headers: { 'Authorization': `Bearer ${user?.access_token}` } }),
      (data) => {
        initialResultsAcquired = true;
        setCurrentQuery(v => abandoned ? v : pendingQuery);
        setFetching(v => abandoned ? v : false);
        setItems(v => abandoned ? v : data as IAssets);
      },
      () => {
        setError(v => abandoned ? v : `Unable to ${initialResultsAcquired ? 'refresh' : 'retrieve'} the projects list.`);
      },
      fetchConfig);

    return () => {
      abandoned = true;
      stopFetch();
    };
  }, [pendingQuery, user]);

  return <PageContent>
    <HubAside current='assets' />
    <PageHeader
      title={("Assets")}
      subTitle={("Browse all the data available for use in projects")}
    >
      <div
        style={{
          display: 'flex',
          gap: 4,
          alignContent: 'center',
          flexGrow: 1,
          maxWidth: 'max-content',
          width: 'min-content',
          flexWrap: 'wrap',
        }}
      >
        <div style={{
          display: 'flex',
          gap: '0.5em',
          flexGrow: 1,
        }} >
          <label style={{ marginTop: 'auto', marginBottom: 'auto', whiteSpace: 'nowrap', fontWeight: 'bold' }}>Order by</label>
          <Select
            style={{
              display: 'flex',
              flexGrow: 1,
              width: 'auto',
              height: 'unset',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
            value={sortOrder}
            onChange={e => setOrder(e.target.value as AssetOrderBy)}>
            <option value={AssetOrderBy.NameASC}>{'Name \u{2191}'}</option>
            <option value={AssetOrderBy.NameDESC}>{'Name \u{2193}'}</option>
          </Select>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '0.5em',
            flexGrow: 1,
          }}>
          <Input
            style={{ flexGrow: 1 }}
            type='search'
            size={12}
            placeholder='Search library'
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
          />
          <Button
            primary
            href='import'
            title='Create a new asset'
          >
            <FaPlus />
            {'\u{00A0}'}
            <span>New asset</span>
          </Button>
        </div>
      </div>
    </PageHeader>

    <div style={{ margin: '0px 24px' }} >
      {error && <Alert severity="warning">{`${error}. Please refresh the page to try again.`}</Alert>}

      <div style={{ display: 'flex', alignItems: 'center' }} >
        <h2>{
          (currentQuery?.get('ModelNameFilter') ?? '').length > 0
            ? <>Assets containing {'\u{27}'}{currentQuery?.get('ModelNameFilter')}{'\u{27}'}</>
            : <>All assets</>}</h2>
        {'\u{00A0}'}
        {fetching && !error && <Spinner />}
      </div>

      <CardList layout='wide'>
        {items && items.vrModels.map((p) => <Card
          key={p.id}
          header={p.name}
          actions={
            <Button
              href={`import?id=${p.id}`}
              title='Upload a new version of this asset'
            >Update</Button>
          }
        >
          Version {p.revisionCount}
        </Card>)}
      </CardList>
    </div>

    {items && items.totalResults > PAGE_SIZE &&
      <footer>
        <PaginationWrapper>
          <Pagination
            onPageRequest={setCurrentPage}
            currentPage={parseInt(currentQuery?.get('PageNumber') ?? '0')}
            totalPageCount={Math.ceil(items.totalResults / PAGE_SIZE)}
          />
        </PaginationWrapper>
      </footer>
    }
  </PageContent>;
}
