import { Children, type ComponentPropsWithoutRef, type PropsWithChildren } from "react";
import css from './Stepper.module.scss';
export default function Stepper({ children, currentStep, autoHorizontal, className, style, ...props }: PropsWithChildren<ComponentPropsWithoutRef<'div'> & {
  currentStep?: number;
  autoHorizontal?: boolean;
}>) {

  let gridTemplateAreas = '';
  if (!children)
    return null;
  const numberOfStages = Children.count(children);
  for (let i = 0; i < numberOfStages; i++) {
    const previousStage = i > 0 ? `progress-${i}` : '.';
    const nextStage = i !== (numberOfStages - 1) ? `progress-${i + 1}` : '.';

    gridTemplateAreas += (`'${previousStage} .'\n`);
    gridTemplateAreas += (`'${previousStage} item-${i}'\n`);
    gridTemplateAreas += (`'stage-${i} item-${i}'\n`);
    gridTemplateAreas += (`'${nextStage} item-${i}'\n`);
    gridTemplateAreas += (`'${nextStage} .'\n`);
  }

  return <div
    className={
      css['stepper'] +
      (autoHorizontal ? ` ${css['horz']}` : '') +
      (className ? ` ${className}` : '')}
    style={{ gridTemplateAreas, ...style }}
    {...props}
  >
    {Children.map(children, (C, i) => {
      return <>
        <div style={{ gridArea: `stage-${i}` }} className={i <= (currentStep ?? 0) ? css['active'] : undefined}></div>
        {i > 0 && <span className={i <= (currentStep ?? 0) ? css['progress'] : undefined} style={{ gridArea: `progress-${i}` }}>{'\u{00A0}'}</span>}
        <p style={{ gridArea: `item-${i}` }}>
          {C}
        </p>
      </>;
    })}
  </div>;
}