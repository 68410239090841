import type { HTMLAttributes } from 'react';
import css from './Spinner.module.scss';

export default function Spinner({ children, ...props }: HTMLAttributes<HTMLSpanElement>) {
  return <span {...props} >
    {children && <strong>{children}{'\u{00A0}'}</strong>}
    <div className={css['spinner']}>
      <div>
        <div />
      </div>
    </div>
  </span>;
}
