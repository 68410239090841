export default class PacketWriter {
  private view: DataView;
  private byteOffset: number;

  constructor(src: ArrayBuffer = new ArrayBuffer(4096)) {
    this.view = new DataView(src);
    this.byteOffset = 2; // Automatically account for the header
  }

  set type(t: number) {
    this.view.setUint16(0, t, true);
  }

  get buffer(): ArrayBufferView {
    return new DataView(this.view.buffer, 0, this.byteOffset);
  }

  setInt8(value: number) {
    this.view.setInt8(this.byteOffset, value);
    this.byteOffset += 1;
  }
  setInt16(value: number) {
    this.view.setInt16(this.byteOffset, value, true);
    this.byteOffset += 2;
  }
  setInt32(value: number) {
    this.view.setInt32(this.byteOffset, value, true);
    this.byteOffset += 4;
  }
  setInt64(value: bigint) {
    this.view.setBigInt64(this.byteOffset, value, true);
    this.byteOffset += 8;
  }

  setUint8(value: number) {
    this.view.setUint8(this.byteOffset, value);
    this.byteOffset += 1;
  }
  setUint16(value: number) {
    this.view.setUint16(this.byteOffset, value, true);
    this.byteOffset += 2;
  }
  setUint32(value: number) {
    this.view.setUint32(this.byteOffset, value, true);
    this.byteOffset += 4;
  }
  setUint64(value: bigint) {
    this.view.setBigUint64(this.byteOffset, value, true);
    this.byteOffset += 8;
  }

  setFloat32(value: number) {
    this.view.setFloat32(this.byteOffset, value, true);
    this.byteOffset += 4;
  }
  setFloat64(value: number) {
    this.view.setFloat64(this.byteOffset, value, true);
    this.byteOffset += 8;
  }

  setBool(value: boolean) {
    this.view.setUint8(this.byteOffset, value ? 1 : 0);
    this.byteOffset += 1;
  }

  setString(value: string) {
    const te = new TextEncoder();
    const dst = new Uint8Array(this.view.buffer, this.byteOffset);
    const res = te.encodeInto(value, dst);

    this.byteOffset += res.written || 0;
    // if nothing was written or it wasn't null terminated. Add the null terminator
    if (!res.written || dst[res.written - 1] !== 0)
      this.setUint8(0);
  }
}