import { useContext, useEffect, useState } from "react";
import css from './General.module.scss';
import ProjectContext, { isPublishAction, type PublishAction } from "./ProjectContext";
import GroupSelector from "../components/GroupSelector";

export default function Access() {
  const project = useContext(ProjectContext);
  // this assumes there's only a single publish action (unsure if advanced modifications can perform more)
  const publishAction = project.actions.find(isPublishAction);
  const [audience, setAudience] = useState<ReadonlySet<string>>();

  const audienceWithFallback = audience ?? new Set(publishAction?.audience);

  useEffect(() => {
    if (audience === undefined)
      return;

    let actions = [...project.actions];
    // get original position of first avaliable publishing action (if present)
    const insertPosition = actions.findIndex(isPublishAction);
    // new publish action instance
    const publishAction = {
      ...(insertPosition >= 0 ? actions[insertPosition] as PublishAction : {
        name: project.name ?? 'UNKNOWN_NAME',
        type: 'PUBLISH'
      }),
      audience: [...audience],
    } satisfies PublishAction;
    // remove any publishing actions ready to insert our own
    actions = actions.filter(v => !isPublishAction(v));
    // insert at original position or at the end of the actions array
    actions.splice(insertPosition >= 0 ? insertPosition : actions.length, 0, publishAction);

    project.setProjectState({ ...project, actions });
  }, [audience, project, publishAction]);


  return <div className={css['controls']} >
    <GroupSelector selectedGroupState={[audienceWithFallback, setAudience]} >
      Who has access to the visualisations
    </GroupSelector>
  </div>;
}
