import { useEffect, useState } from "react";

export default function UsingDarkTheme() {
  const colorScheme = matchMedia('(prefers-color-scheme: dark)');
  const [darkTheme, setDarkTheme] = useState(colorScheme.matches);
  useEffect(() => {
    let aborted = false;
    colorScheme.onchange = (e) => {
      setDarkTheme(v => aborted ? v : e.matches);
    };

    return () => {
      aborted = true;
      colorScheme.onchange = null;
    };
  }, [colorScheme]);
  return darkTheme;
}
