import { Children, useMemo, useRef } from 'react';
import css from './Pagination.module.scss';

interface PaginationProps {
  currentPage: number;
  totalPageCount: number;
  onPageRequest: (page: number) => void;
}

function range(start: number, end: number) {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
}

const DOTS = -1;

function usePagination({
  totalPageCount,
  siblingCount = 1,
  currentPage
}: { totalPageCount: number, siblingCount?: number, currentPage: number; }) {
  const paginationRange = useMemo(() => {
    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    // Case 1:
    // If the number of pages is less than the page numbers we want to show in our
    // paginationComponent, we return the range [1..totalPageCount]
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    // Calculate left and right sibling index and make
    // sure they are within range 1 and totalPageCount
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    // We do not show dots just when there is just one page
    // number to be inserted between the extremes of sibling
    // and the page limits i.e 1 and totalPageCount.Hence we
    // are using leftSiblingIndex > 2 and
    // rightSiblingIndex < totalPageCount - 2
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    // Case 2: No left dots to show, but rights dots to be shown
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    // Case 3: No right dots to show, but left dots to be shown
    if (shouldShowLeftDots && !shouldShowRightDots) {

      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    // Case 4: Both left and right dots to be shown
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalPageCount, siblingCount, currentPage]);

  return paginationRange;
}

export default function Pagination({ currentPage, totalPageCount, onPageRequest }: PaginationProps) {
  const activeElement = useRef<HTMLDivElement>(null);

  const paginationRange = usePagination({
    currentPage,
    totalPageCount
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || !paginationRange || paginationRange.length < 2) {
    return null;
  }

  return <div className={css["pagination"]}>
    <div
      className={currentPage === 1 ? css['disabled'] : undefined}
      onClick={() => onPageRequest(Math.max(1, currentPage - 1))}
    >&laquo;</div>
    {Children.map(paginationRange, (index) => {
      // If the pageItem is a DOT, render the DOTS unicode character
      if (index === DOTS)
        return <div className={css['disabled']} >&#8230;</div>;

      return <div
        ref={index === currentPage ? activeElement : undefined}
        className={index === currentPage ? css["active"] : undefined}
        onClick={() => onPageRequest(index)}
      >
        {index}
      </div>;
    })}
    <div
      className={currentPage === totalPageCount ? css['disabled'] : undefined}
      onClick={() => onPageRequest(Math.min(totalPageCount, currentPage + 1))}
    >&raquo;</div>
  </div>;
}