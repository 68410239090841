import { useState } from 'react';
import ControlContext from './ControlContext';
import FramerateMonitor from './FramerateMonitor';
import SystemStatus from './SystemStatus';
import ActiveWebXR from './ActiveWebXR';
import Tree from './Tree';
import Main from './Main';
import './page.scss';
import ClientControls from './ClientControls';

export function Page() {
  const [setTimings, setTimingsCallback] = useState<ControlContext['setTimings']>(null);
  const [setConnectionStatus, setConnectionStatusCallback] = useState<ControlContext['setConnectionStatus']>(null);
  const [setMeshesPending, setMeshesPendingCallback] = useState<ControlContext['setMeshesPending']>(null);
  const [setDrawablesPending, setDrawablesPendingCallback] = useState<ControlContext['setDrawablesPending']>(null);
  const [setBroadOccluderCount, setBroadOccluderCountCallback] = useState<ControlContext['setBroadOccluderCount']>(null);
  const [setDrawCallCount, setDrawCallCountCallback] = useState<ControlContext['setDrawCallCount']>(null);
  const [setTriangleCount, setTriangleCountCallback] = useState<ControlContext['setTriangleCount']>(null);
  const [setDevice, setDeviceCallback] = useState<ControlContext['setDevice']>(null);
  const [setDebugAnimation, setDebugAnimationCallback] = useState<ControlContext['setDebugAnimation']>(null);
  const [setParticipationID, setParticipationIDCallback] = useState<ControlContext['setParticipationID']>(null);
  const [setSettings, setSettingsCallback] = useState<ControlContext['setSettings']>(null);
  const [setBlinkTo, setBlinkToCallback] = useState<ControlContext['setBlinkTo']>(null);
  const [setFadeIn, setFadeInCallback] = useState<ControlContext['setFadeIn']>(null);
  const [statsMonitor, setStatsMonitor] = useState<ControlContext['statsMonitor']>(null);

  return (
    <>
      <ControlContext.Provider value={{
        setTimings, setTimingsCallback,
        setConnectionStatus, setConnectionStatusCallback,
        setMeshesPending, setMeshesPendingCallback,
        setDrawablesPending, setDrawablesPendingCallback,
        setBroadOccluderCount, setBroadOccluderCountCallback,
        setDrawCallCount, setDrawCallCountCallback,
        setTriangleCount, setTriangleCountCallback,
        setDevice, setDeviceCallback,
        setDebugAnimation, setDebugAnimationCallback,
        setParticipationID, setParticipationIDCallback,
        setSettings, setSettingsCallback,
        setBlinkTo, setBlinkToCallback,
        setFadeIn, setFadeInCallback,
        statsMonitor, setStatsMonitor,
      }} >
        <div style={{
          display: 'grid',
          width: '100%',
          minHeight: 'calc(100vh - 48px)',
          height: 'max-content',
          inset: '48px 0 0 0',
          position: 'absolute',
          gridTemplateRows: '1fr auto 1fr',
          gridTemplateColumns: '100vw',
          isolation: 'isolate',
          overflowX: 'hidden',
        }}>
          <header style={{
            display: 'grid',
            gridTemplateColumns: '1fr auto 1fr',
            whiteSpace: 'nowrap',
            pointerEvents: 'none',
          }} >
            <SystemStatus />

            <div style={{ gridColumn: 2 }} >
              <FramerateMonitor />
            </div>

            <div style={{ gridColumn: 3, marginLeft: 'auto', pointerEvents: 'auto' }} >
              <ClientControls />
            </div>
          </header>

          <Tree />

          <footer style={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr auto',
            whiteSpace: 'nowrap',
            alignItems: 'end',
            pointerEvents: 'none',
          }} >
            <ActiveWebXR />
          </footer>

          <Main />

        </div>
      </ControlContext.Provider>
    </>
  );
}
