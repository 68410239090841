import { useContext, useEffect, useState } from "react";
import ControlContext, { type SetDeviceCallback } from "./ControlContext";
import XRButton from './XRButton';

export default function ActiveWebXR() {
  const [setDevice, setDeviceFunctionCallback] = useState<SetDeviceCallback>({});
  const { setDeviceCallback } = useContext(ControlContext);
  const [vrSupported, setVrSupported] = useState<boolean>(false);
  const [arSupported, setArSupported] = useState<boolean>(false);

  useEffect(() => {
    // this should be an array or map or something so it can store multiple values
    setDeviceCallback(() => setDeviceFunctionCallback);

    return () => {
      setDeviceCallback(null);
    };
  }, [setDeviceCallback]);

  useEffect(() => {
    if (!navigator.xr)
      return;

    let aborted = false;

    Promise.all([
      navigator.xr.isSessionSupported('immersive-vr').catch(() => false),
      navigator.xr.isSessionSupported('immersive-ar').catch(() => false)
    ]).then(([vrSupport, arSupport]) => {
      if (aborted)
        return;
      setVrSupported((old) => aborted ? old : vrSupport);
      setArSupported((old) => aborted ? old : arSupport);
    }).catch(console.warn);

    return () => {
      aborted = true;
    };
  }, [setArSupported, setVrSupported]);

  return (
    <div
      style={{
        display: 'flex',
        gridColumn: 3,
        padding: 6,
        gap: 10,
        pointerEvents: 'auto',
      }}
    >
      {vrSupported && setDevice.handle ? <XRButton setDevice={setDevice.handle} mode="vr" /> : <></>}
      {arSupported && setDevice.handle ? <XRButton setDevice={setDevice.handle} mode="ar" /> : <></>}
    </div>
  );
}
