import { type AnchorHTMLAttributes, type ButtonHTMLAttributes } from 'react';
import css from './Controls.module.scss';

type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};

type ButtonProps = (
  { primary: boolean; } |
  { transparent: boolean; } |
  NonNullable<unknown>
) & {
  sm?: boolean;
};

export default function Button(v: ButtonProps & (
  ButtonHTMLAttributes<unknown> |
  WithRequiredProperty<AnchorHTMLAttributes<unknown>, 'href'>
)) {
  const { primary, transparent, sm, className, ...props } = { sm: false, primary: false, transparent: false, ...v };

  let cn = css['control'] + (className ? ` ${className}` : '');
  if (primary)
    cn += ` ${css['primary']}`;
  else if (transparent)
    cn += ` ${css['transparent']}`;

  if (sm)
    cn += ` ${css['small']}`;

  return 'href' in props ?
    <a className={cn} {...props} /> :
    <button className={cn} {...props} />;
}
